import React, { useState } from "react"
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { sendMail } from "../../../../../../services/mail"

import "./index.scss"
import LottieAnimation from "../../../../../../animations/lottie"
const ContactForm = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, "*Name must be less than 50 characters")
      .required("*This field is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*This field is required"),
    // company: Yup.string()
    //   .max(100, "*Name must be less than 100 characters")
    //   .required("*This field is required"),
    note: Yup.string().required("*This field is required"),
    submit: Yup.string(),
  })
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)

  return (
    <div id="ContactForm" className="contact-form-entreprise   ">
      <div className="container col-12 col-md-10   ">
        <Formik
          initialValues={{
            name: "",
            email: "",
            note: "",
            submit: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setStatus, setErrors }
          ) => {
            setSubmitting(true)

            const res = await sendMail(values, window.location.href)

            if (res.success) {
              resetForm({})
              setStatus({ success: true })
              setShowSuccessMsg(true)
            } else {
              setStatus({ success: false })
              setErrors({ submit: res.message })
            }

            setTimeout(() => {
              setShowSuccessMsg(false)
            }, 3000)

            setSubmitting(false)
          }}
        >
          {({ isSubmitting, touched, errors }) => (
            <Form>
              <div className="form-input">
                <label htmlFor="Name" className="form-label">
                  Name*
                </label>
                <Field
                  type="text"
                  name="name"
                  className={`form-control ${
                    touched.name && errors.name ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-input">
                <label htmlFor="Business-Email" className="form-label">
                  Business Email*
                </label>
                <Field
                  type="email"
                  name="email"
                  className={`form-control ${
                    touched.email && errors.email ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-input">
                <label htmlFor="Message" className="form-label">
                  Message
                </label>
                <Field
                  type="text"
                  name="note"
                  component="textarea"
                  className={`form-control ${
                    touched.note && errors.note ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="note"
                  component="div"
                  className="error-message"
                />
              </div>
              <button
                type="submit"
                name="submit"
                disabled={isSubmitting}
                className="form-button submit-button "
              >
                Submit
                {!isSubmitting && <i className="bi bi-arrow-right-short "></i>}
                {isSubmitting && (
                  <div className="login-loader">
                    <LottieAnimation height={25} width={25} />
                  </div>
                )}
              </button>
              <ErrorMessage
                name="submit"
                component="div"
                className="error-message"
              />
              {showSuccessMsg && (
                <div className="success-msg">
                  <i className="bi bi-envelope-fill"></i>
                  <h6>
                    we have received your request, we will answer you as soon as
                    possible.
                  </h6>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ContactForm
