import { graphql, useStaticQuery } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import { Modal } from "react-bootstrap"
import ReactPaginate from "react-paginate"

import { readFromStorage } from "../../../../../services/utils"
import { ProfileContext } from "../../../dashboard/membership-layout"
import EntreprisePlan from "../../plan-order/entreprise-plan"
import SelectPlan from "../../plan-order/plan"
import Documentation from "../documentation"
import Order from "../order"

import "./index.scss"
const LicenseTable = () => {
  const table_columns = [
    "Number of users",
    "Purchase date",
    "Expiration date",
    "",
  ]

  let pricingCards = []

  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(pricing-cards)/" }) {
          exports {
            pricingCard {
              btn
              list {
                imgSrc
                specification
              }
              price {
                price
                trial
              }
              subTitle
              title
              code
            }
          }
          frontmatter {
            subTitleMainPricing
            titlecolored
            blackTitle
            bottominformations
            bottomButtonTitle
          }
        }
      }
    `)

    pricingCards = result.mdx.exports.pricingCard
  } catch (error) {
    console.error(error)
  }

  const currentUser = useContext(ProfileContext)
  let currentUsersorted = currentUser?.purchasesHistory
    ? Object.values(currentUser.purchasesHistory).sort((a, b) =>
        b.purchaseDate
          .split("/")
          .reverse()
          .join()
          .localeCompare(a.purchaseDate.split("/").reverse().join())
      )
    : []

  const userCount = currentUser?.purchasesHistory
    ? Object.values(currentUser.purchasesHistory).reduce(
        (accumulateur, currentElm) => {
          return accumulateur + parseInt(currentElm.usersNumber)
        },
        0
      )
    : 0

  const perPage = 3

  const [accountType, setAccountType] = useState(
    currentUser?.plan?.toLowerCase()
  )
  const [showModal, setShowModal] = useState(false)
  const [purchasesHistory, setPurchasesHistory] = useState(
    currentUsersorted ? Object.values(currentUsersorted) : []
  )
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState(
    purchasesHistory.slice(offset, offset + perPage)
  )

  const pageCount = Math.ceil(purchasesHistory.length / perPage)

  const handlePageClick = e => {
    const selectedPage = e.selected
    const offset = selectedPage * perPage
    setOffset(offset)
    setData(purchasesHistory.slice(offset, offset + perPage))
  }

  useEffect(() => {
    setAccountType(currentUser?.plan?.toLowerCase())
    setPurchasesHistory(
      currentUsersorted ? Object.values(currentUsersorted) : []
    )
    setData(
      currentUsersorted
        ? Object.values(currentUsersorted).slice(offset, offset + perPage)
        : []
    )

    readFromStorage(({ currentPurchaseLS }) => {
      const showPurchase =
        currentPurchaseLS &&
        (!currentUser?.plan ||
          currentUser?.plan.toLowerCase() ===
            currentPurchaseLS.type?.toLowerCase())
          ? true
          : false

      typeof window !== "undefined" &&
        localStorage.removeItem("currentPurchase")

      if (showPurchase) {
        setAccountType(currentPurchaseLS?.type?.toLowerCase())
        setShowModal(true)
      }
    })

    return () => {}
  }, [currentUser])

  return currentUser ? (
    <div id="LicenseTable" className="LicenseTable" data-testid="LicenseTable">
      <div className="table-heading">
        {purchasesHistory.length > 0 && (
          <div className="license-key">
            <img alt="maxapps maximo: licence-key icon" src="/key.svg" />
            <span className="table-title">License Key </span>
            <span className="key">{currentUser.licenceKey}</span>
          </div>
        )}
        <div className="dflex">
          <span className="user-count">
            <span className="purple-number">{currentUser?.freeUsers}</span>Free
            Users
          </span>
          <span className="user-count">
            <span className="purple-number">
              {userCount - currentUser?.freeUsers}
            </span>
            Paid
          </span>

          {currentUser.plan ? (
            currentUser?.plan?.toLowerCase() !== "enterprise" ? (
              <button
                className="primary-btn text-nowrap"
                onClick={() => {
                  setShowModal(true)
                }}
                data-testid="purchase-btn"
              >
                <img
                  alt="maxapps maximo: purchase-diy icon"
                  src="/purchase.svg"
                  className="purchase-icon"
                ></img>
                Purchase more
              </button>
            ) : (
              <button
                className="primary-btn text-nowrap"
                onClick={() => {
                  setShowModal(true)
                }}
              >
                Contact us
              </button>
            )
          ) : (
            <button
              className="primary-btn text-nowrap"
              onClick={() => {
                setShowModal(true)
              }}
            >
              Select a Plan
            </button>
          )}
        </div>
      </div>
      {purchasesHistory.length > 0 && (
        <>
          <table>
            <thead>
              <tr className="col">
                {table_columns.map((column, index) => {
                  return <th key={index}>{column}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr className="table-row" key={index}>
                    <td data-label="Number of users">
                      {item.usersNumber +
                        (item.usersNumber > 1 ? " users" : " user")}
                    </td>
                    <td data-label="Purchase date">{item.purchaseDate}</td>
                    <td data-label="Expiration date">{item.experationDate}</td>
                    <td>
                      {isActive(item.experationDate) ? (
                        <div
                          className="license-active disabled"
                          data-testid="active-btn"
                        >
                          Active
                        </div>
                      ) : (
                        <div
                          className="license-expired disabled"
                          data-testid="expired-btn"
                        >
                          Expired
                        </div>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className="table-pagination">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={".."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </>
      )}
      <Documentation />

      <Modal
        dialogClassName="licence-table-modal"
        show={showModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="row">
            <div className="complete-pur col-11 ps-md-5 pt-3">
              Complete your purchase
            </div>
            <div className="text-end col-1">
              <i
                className="bi bi-x-lg "
                onClick={() => {
                  setAccountType(currentUser.plan?.toLowerCase())
                  setShowModal(false)
                }}
              ></i>
            </div>
          </div>
          {accountType ? (
            accountType === "diy" ? (
              <Order />
            ) : (
              <EntreprisePlan />
            )
          ) : (
            <SelectPlan
              pricingCards={pricingCards}
              startPurchase={setAccountType}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  ) : (
    <>no current user</>
  )
}

const isActive = date => {
  const newDate = new Date()
  const dd = String(newDate.getDate()).padStart(2, "0")
  const mm = String(newDate.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = newDate.getFullYear()

  const today = new Date(yyyy, mm - 1, dd)

  try {
    var dateParts = date.split("/")
    return today <= new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
  } catch (error) {
    return false
  }
}
export default LicenseTable
