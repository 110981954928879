import React from "react"

import LoginLayout from "../../../layout/login-layout"
import Seo from "../../../seo"

import "./index.scss"
const Verification = () => {
  return (
    <LoginLayout>
      <Seo title="Verification" />
      <div id="Verification" className="Verification">
        <div className=" d-flex justify-content-center ">
          <div className="box col-12  row">
            <div className="SectionOfImages col-6 d-none d-lg-block ">
              <div className="text-center">
                <img
                  alt="MAXapps: Verification "
                  title="MAXapps: Verification "
                  src="/mail.svg"
                  className="loginImg-style"
                />
              </div>
            </div>

            <div className="form-section col-12 col-lg-6 justify-content-center pt-4">
              <div className=" message pt-4 ">
                <h1>Almost Done !</h1>
                <p>Thanks for choosing us</p>
                <div className="pt-4 ">
                  <p>
                    Our administrator will check your request, you will receive
                    an email soon
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default Verification
