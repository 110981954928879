import React, { useState, useEffect } from "react"

import AdminHeader from "./header"
import LayoutGray from "../dashboard-layout/layout"
import { ProfileContext } from "../dashboard/membership-layout"
import { readFromStorage } from "../../../services/utils"
import Sidebar from "../dashboard/sidebar"
import {
  adminSideBarItemsList,
  getComponent,
} from "../dashboard/sidebar/sidebar-entries"

const ConfirmMails = () => {
  const { Render: RenderSideBar, displayed } = Sidebar({
    sideBarItemsList: adminSideBarItemsList,
  })

  const Component = getComponent(adminSideBarItemsList, displayed)

  const [profile, setProfile] = useState(null)

  useEffect(() => {
    if (!profile) {
      readFromStorage(({ profile }) => {
        setProfile(profile)
      })
    }

    return () => {}
  }, [profile])

  return (
    profile && (
      <LayoutGray>
        <ProfileContext.Provider value={profile}>
          <AdminHeader />
        </ProfileContext.Provider>
        <div id="ConsoleLayout" className="ConsoleLayout d-flex">
          {RenderSideBar()}

          <div className="consoleContent  col-lg-9  col-md-9  ">
            {Component ? <Component /> : <>component not found : {displayed}</>}
          </div>
        </div>
      </LayoutGray>
    )
  )
}

export default ConfirmMails
