import React, { useState } from "react"
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { navigate } from "gatsby"
import "./index.scss"
import Service from "../../../../../services"
import LottieAnimation from "../../../../../animations/lottie"

const sendPasswordResetLink = Service.firebaseServices.getCloudFunction(
  "sendPasswordResetLink"
)
const ResendlinkForm = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
  })

  const [forgetPwdError, setForgetPwdError] = useState({
    show: false,
    msg: null,
  })

  return (
    <div id="ResendlinkForm" className="ResendlinkForm ">
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)

          const user = await Service.db.getUSer(values.email)
          if (!user || !user.emailApproved) {
            setForgetPwdError({
              show: true,
              msg: "email not exist or account not approved",
            })
          }

          const res =
            user &&
            user.emailApproved &&
            (await sendPasswordResetLink({
              useremail: values.email,
            }))
          if (res?.data?.success) navigate("/console/welcome-msg/")
          else setForgetPwdError({ show: true, msg: res.data.error })

          setSubmitting(false)
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <div className="form-input">
              <label htmlFor="Email" className="form-label">
                Work Email
              </label>
              <Field
                type="email"
                name="email"
                className={`form-control ${
                  touched.email && errors.email ? "error" : null
                }`}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="error-message"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="form-button login-button "
            >
              Verify
              {!isSubmitting && <i className="bi bi-arrow-right-short "></i>}
              {isSubmitting && (
                <div className="login-loader">
                  <LottieAnimation height={25} width={25} />
                </div>
              )}
            </button>
            {forgetPwdError.show && (
              <div className="server-error">
                <i className="bi bi-exclamation-triangle-fill"></i>
                <h6>{forgetPwdError.msg}</h6>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ResendlinkForm
