import React, { useEffect, useRef, useState } from "react"
import MaterialTable from "material-table"

import "./index.scss"
import { tableIcons } from "./config"
import service from "../../../../services"

function ContactTable({
  title = "",
  columns = [],
  data = [],
  selectedContacts,
  options = {},
}) {
  const [tableData, setTableData] = useState(data)

  const setSelectedContacts = rows => {
    selectedContacts.current = rows
  }

  useEffect(() => {
    setTableData(data)
  }, [data])

  return (
    <div id="UsersTable" className="contact-table">
      <MaterialTable
        icons={tableIcons}
        title={title}
        columns={columns}
        data={tableData}
        editable={{
          onRowAdd: newRow =>
            new Promise(async (reject, resolve) => {
              try {
                const res = await service.db.addContact(newRow)
                if (res.success) {
                  setTableData([...tableData, newRow])
                  resolve()
                } else reject()
              } catch (error) {
                console.error("error adding new contact: ", error)
                reject()
              }
            }),
          onRowUpdate: (newRow, oldRow) =>
            new Promise(async (reject, resolve) => {
              try {
                const res = await service.db.editContact(oldRow.email, newRow)

                if (res.success) {
                  const updatedData = [...tableData]
                  updatedData[oldRow.tableData.id] = newRow
                  setTableData(updatedData)
                  resolve()
                } else reject()
              } catch (error) {
                console.error("error editing the contact: ", error)
                reject()
              }
            }),
          onRowDelete: selectedRow =>
            new Promise(async (reject, resolve) => {
              try {
                const res = await service.db.deleteContact(selectedRow.email)

                if (res.success) {
                  const updatedData = [...tableData]
                  updatedData.splice(selectedRow.tableData.id, 1)
                  setTableData(updatedData)
                  resolve()
                } else reject()
              } catch (error) {
                reject(error)
              }
            }),
        }}
        onSelectionChange={selectedRows => setSelectedContacts(selectedRows)}
        options={{
          actionsColumnIndex: -1,
          selection: true,
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          rowStyle: (data, index) =>
            index % 2 === 0 ? { background: "#f5f5f5" } : null,
          paginationType: "stepped",
          pageSize: 10,
          pageSizeOptions: [5, 10, 25, 50, 100],
          ...options,
        }}
      />
    </div>
  )
}

export default ContactTable
