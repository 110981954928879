import React, { useState } from "react"
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"

import Services from "../../../../../services"
import LottieAnimation from "../../../../../animations/lottie"

import "./index.scss"
const ResetPasswordForm = ({
  setResetpwd,
  setSuccmsg,
  setResult,
  handleReset,
}) => {
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(6, "Password should be at least 6 characters long")
      .required("*Please enter the current password"),
    newPassword: Yup.string()
      .min(6, "Password should be at least 6 characters long")
      .required("*Please enter the new password"),
    confirmPassword: Yup.string().when("newPassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both passwords need to be the same"
      ),
    }),
  })

  const [responseMsg, setResponseMsg] = useState({
    show: false,
    isError: false,
    msg: null,
  })

  return (
    <div
      id="ResetPasswordForm"
      className="ResetPasswordForm"
      data-testid="ResetPasswordForm"
    >
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setResponseMsg({ show: false, isError: null, msg: null })

          setSubmitting(true)

          const res = await Services.auth.setPassword(
            values.newPassword,
            values.currentPassword
          )
          if (res.success) {
            setResetpwd(false)
            setSuccmsg(true)
            setResult(true)
            setResponseMsg({
              show: true,
              isError: false,
              msg: "Password updated successfully",
            })
            resetForm({})
          } else {
            setResponseMsg({ show: true, isError: true, msg: res.message })
          }

          setSubmitting(false)
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <div className="reset-form row">
              <Field
                type="password"
                name="currentPassword"
                placeholder="Current password"
                className={`pwd-input col-sm-6 ${
                  touched.currentPassword && errors.currentPassword
                    ? "error"
                    : null
                }`}
              />
              <ErrorMessage
                name="currentPassword"
                component="div"
                className="error-message col-sm-6"
              />
            </div>
            <div className="reset-form row">
              <Field
                type="password"
                name="newPassword"
                placeholder="New password"
                className={`pwd-input col-sm-6 ${
                  touched.newPassword && errors.newPassword ? "error" : null
                }`}
              />
              <ErrorMessage
                name="newPassword"
                component="div"
                className="error-message col-md-6"
              />
            </div>
            <div className="reset-form row">
              <Field
                type="password"
                name="confirmPassword"
                placeholder="Confirm new password"
                className={`pwd-input col-md-6 ${
                  touched.confirmPassword && errors.confirmPassword
                    ? "error"
                    : null
                }`}
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error-message col-md-6"
              />
            </div>
            <div className="btn">
              <button
                type="submit"
                disabled={isSubmitting}
                className="save-btn"
              >
                <i className="bi bi-check2 px-2"></i> Save
                {/* {!isSubmitting && <div className="temp"></div>} */}
                {isSubmitting && (
                  <div className="login-loader">
                    <LottieAnimation height={25} width={25} />
                  </div>
                )}
              </button>
              <button
                type="button"
                onClick={handleReset}
                className="cancel-btn mx-1"
              >
                <i className="bi bi-x-lg px-2"></i> Cancel{" "}
              </button>
            </div>
            {responseMsg.show && responseMsg.isError && (
              <div className="row response-msg error-msg">
                <i className="bi bi-exclamation-triangle-fill col-3 col-sm-2"></i>
                <h6 className="col-9 col-sm-10">Error: {responseMsg.msg}</h6>
              </div>
            )}
            {responseMsg.show && !responseMsg.isError && (
              <div className="response-msg success-msg">
                <i className="bi bi-key-fill"></i>
                <h6>{responseMsg.msg}</h6>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ResetPasswordForm
