import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link, navigate } from "gatsby"
import { FiLogOut } from "react-icons/fi"
import Service from "../../../../services"

import "./index.scss"
import { ProfileContext } from "../../dashboard/membership-layout"
const AdminHeader = props => {
  const { refreshIntervalId } = props
  // const [clicked, setclicked] = useState(true)
  const currentUser = useContext(ProfileContext)
  let info = {}
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/header-console/" }) {
          frontmatter {
            logoMobile
            logoWeb
          }
        }
      }
    `)
    info = data.mdx.frontmatter
  } catch (error) {
    console.log(error)
  }

  const handleLogout = async () => {
    refreshIntervalId && clearInterval(refreshIntervalId)
    await Service.auth.logOut()
    localStorage.clear()
    navigate("/")
  }
  return (
    <div
      id="AdminHeader"
      className="d-flex flex-row AdminHeader"
      data-testid="AdminHeader"
    >
      <div className="AdminHeader d-flex flex-row col-11">
        <Link
          className="navbar-brand d-none d-lg-block"
          to="/"
          data-testid="link1"
        >
          <img
            alt="maximo maxapps"
            src={`${info?.logoWeb}`}
            className="p-0 m-0 imgMaxapps"
            data-testid="img1"
          />
        </Link>
        <Link
          className="navbar-brand d-block d-lg-none "
          to="/"
          data-testid="link2"
        >
          <img
            alt="maximo maxapps"
            src={info?.logoMobile}
            className="p-0 m-0 imgMaxappsMobile "
            data-testid="img2"
          />
        </Link>

        <div className="nav-console-btn ">
          <p className="userName ">{currentUser.profile.displayName}</p>
        </div>

        <button className="logout-btn " onClick={handleLogout}>
          <FiLogOut size={20} />
          Logout
        </button>
      </div>
      <div className="col-1 pt-2 console-list"></div>
    </div>
  )
}

export default AdminHeader
