import { Link } from "gatsby"
import React, { useEffect, useState } from "react"

import Seo from "../../../seo"
import LoginLayout from "../../../layout/login-layout"
import services from "../../../../services"

import "./index.scss"
const EmailValidation = () => {
  const [error, setError] = useState(null)

  async function verifyAccount() {
    const res = await services.auth.validateAccount(window.location.href)

    !res.success && setError(res)
  }
  useEffect(() => {
    verifyAccount()
  }, [])

  return (
    <LoginLayout>
      <Seo title="Validation" />
      <div id="EmailValidation" className="email-validation">
        <div className=" d-flex justify-content-center ">
          <div className="box col-12  row">
            <div className="SectionOfImages col-6 d-none d-lg-block ">
              <div className="text-center">
                <img
                  alt="MAXapps: Validation "
                  title="MAXapps: Validation "
                  src={error ? "/error.svg" : "/mail.svg"}
                  className="loginImg-style"
                />
              </div>
            </div>

            <div className="form-section col-12 col-lg-6 justify-content-center pt-4">
              <div className=" message pt-4 ">
                {error ? (
                  <>
                    <h1>{error.message}</h1>
                    <p className="error-message">Error: {error.error.code}</p>
                  </>
                ) : (
                  <>
                    <h1>Email verified !</h1>
                    <p>Thanks for choosing us</p>
                    <div className="pt-4 ">
                      <Link to="/console/login/">Continue to Console</Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default EmailValidation
