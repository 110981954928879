import React, { useEffect } from "react"
import { navigate } from "gatsby"

import { isLoggedIn, readFromStorage } from "../../services/utils"

const ProtectedRoutes = ({
  shouldBeConnected = false,
  component: Component,
  location,
  ...rest
}) => {
  useEffect(() => {
    if (!isLoggedIn() && shouldBeConnected && typeof window !== "undefined") {
      navigate("/console/login/")
    }
    readFromStorage(({ profile }) => {
      if (isLoggedIn()) {
        if (profile?.admin) navigate("/console/admin/")
        else navigate("/console/")
      }
    })
  }, [shouldBeConnected])
  return <Component {...rest} />
}

export default ProtectedRoutes
