import React from "react"

import ResetPwdForm from "./resetpwd-form"
import Seo from "../../../seo"

import "./index.scss"
import LoginLayout from "../../../layout/login-layout"
function ResetPwd() {
  return (
    <LoginLayout>
      <Seo title="Reset Password" />

      <div id="ResetPwd" className=" ResetPwd ">
        <h1>Reset Password</h1>

        <div className=" d-flex justify-content-center ">
          <div className="box col-12  row">
            <div className="SectionOfImages col-6 d-none d-lg-block ">
              <div className="text-center">
                <img
                  alt="MAXapps: Reset Password"
                  title="MAXapps: Reset Password"
                  src="/yes.svg"
                  className="loginImg-style"
                />
              </div>
            </div>

            <div className="form-section col-12 col-lg-6">
              <div className=" col-12  col-lg-7 col-xl-8  col-xxl-9   py-2">
                <div className="d-flex flex-row pt-4">
                  <img
                    alt="MAXapps: Reset Password"
                    title="MAXapps: Reset Password"
                    src="/check-mark.svg"
                    className="icon"
                  />
                  <p className="desc"> Reset Password</p>
                </div>
                <h3 className=" pt-4">One more step</h3>
              </div>

              <div className="pt-2 ">
                <ResetPwdForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default ResetPwd
