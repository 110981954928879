import React, { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import services from "../../../services"
import CheckoutForm from "../membership/card-main/order/order-step3/CheckoutForm"
import LottieAnimation from "../../../animations/lottie"

const createStripeCheckoutIntent = services.firebaseServices.getCloudFunction(
  "stripeCheckoutIntent"
)

export default function Pay2({ data }) {
  const stripe = loadStripe(process.env.GATSBY_stripePublicKey)

  const [options, setOptions] = useState({})

  const stripesessionid = async payload => {
    if (Object.keys(options).length === 0) {
      try {
        const response = await createStripeCheckoutIntent({
          ...payload,
          authorization: `Bearer ${services.auth.auth.currentUser.stsTokenManager.accessToken}`,
        })

        const clientSecret = response.data.sessionId
        setOptions({
          clientSecret,
          appearance,
        })

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
  }
  useEffect(() => {
    stripesessionid(data)
    return () => {}
  }, [])

  const appearance = {
    theme: "stripe",
  }

  return (
    <div className="App">
      {Object.keys(options).length === 0 ? (
        <div className="pt-5 mt-5">
          <LottieAnimation height={90} width={90} />
        </div>
      ) : options.clientSecret ? (
        <Elements options={options} stripe={stripe}>
          <CheckoutForm />
        </Elements>
      ) : (
        <span>Not allowed</span>
      )}
    </div>
  )
}
