import React from "react"
import { navigate } from "gatsby"

import RegisterForm from "./register-form"
import Seo from "../../../seo"

import "./index.scss"
import LoginLayout from "../../../layout/login-layout"
import { Helmet } from "react-helmet"
function Register({ imgSrc = "" }) {
  const handleNavigation = () => {
    navigate("/console/login/")
  }
  return (
    <LoginLayout>
      <Seo
        title="Create an account"
        description="create an account and discover all Maximo IBM features"
      />
      <Helmet>
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/sign-up.jpg"
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/sign-up.jpg"
        />
      </Helmet>
      <div id="Register" className="register ">
        <h1>Create An Account</h1>
        <div className=" d-flex justify-content-center ">
          <div className="box col-12  row">
            {imgSrc && (
              <>
                <div className="SectionOfImages col-6 d-none d-lg-block align-self-center">
                  <div className="text-center ">
                    <img
                      alt="MAXapps: create an account"
                      title="MAXapps: create an account"
                      src={imgSrc}
                      className="loginImg-style"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="form-section col-12 col-lg-6 ">
              <div className="row justify-content-xl-end  pb-4">
                <div className="text-center text-lg-end col-12  col-lg-7 py-2">
                  <p>Already have an account ?</p>
                </div>
                <div className="text-center  col-12 col-lg-5 ">
                  <button
                    type="button"
                    className="form-button login-button"
                    onClick={handleNavigation}
                  >
                    Login
                  </button>
                </div>
              </div>
              <div className="pt-4 align-items-center">
                <RegisterForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default Register
