import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import React, { useState, useEffect, useContext } from "react"

import services from "../../../../services"
import ResetPasswordForm from "./reset-password-form"
import { readFromStorage } from "../../../../services/utils"
import { ProfileContext } from "../../dashboard/membership-layout"

import "./index.scss"
const AccountMain = () => {
  const currentUser = useContext(ProfileContext)

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(50, "*Name must be less than 50 characters")
      .min(3, "*Name must be at least 3 characters long")
      .required("*Name is required"),
  })

  const [resetpwd, setResetpwd] = useState(false)
  const [succmsg, setSuccmsg] = useState(false)
  const [result, setResult] = useState(false)
  const [openEditname, setOpenEditName] = useState(false)
  const [userInfos, setUserInfos] = useState(false)

  const handleReset = () => {
    setResetpwd(!resetpwd)
  }

  const showSuccessMsg = () => {
    setSuccmsg(true)
    setTimeout(() => {
      setSuccmsg(false)
    }, 1500)
  }

  const handleCancelClick = () => {
    setOpenEditName(false)
  }

  useEffect(() => {
    if (!userInfos) {
      readFromStorage(({ user }) => {
        setUserInfos({
          displayName: currentUser?.profile?.displayName,
          email: currentUser?.profile?.email,
          createdAt: convertTimestampToReadable(user?.metadata?.createdAt),
        })
      })
    }

    return () => {}
  }, [userInfos])

  return userInfos ? (
    <div>
      <span className="accounttable-title" data-testid="users-list">
        <i className="bi bi-person-fill"></i>
        <p>Account Informations</p>
      </span>
      <div id="AccountMain" className="AccountMain" data-testid="AccountMain">
        <div className="account-infos">
          <div className="info-row user-name">
            <span className="info-label">Name:</span>
            {!openEditname && (
              <div className="info-data">
                <span>{currentUser.profile.displayName}</span>
                <button
                  className="edit-btn"
                  onClick={() => {
                    setOpenEditName(true)
                  }}
                >
                  <img src="/edit.svg" alt="" />
                </button>
              </div>
            )}

            {openEditname && (
              <div className=" d-flex update-name">
                <Formik
                  initialValues={{ name: currentUser.profile.displayName }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true)

                    await services.auth.updateAccountName({
                      displayName: values.name,
                    })

                    await services.db.editUSer(currentUser.id, {
                      profile: {
                        displayName: values.name,
                      },
                    })
                    setOpenEditName(false)
                    setSubmitting(false)
                  }}
                >
                  {({ isSubmitting, touched, errors }) => (
                    <Form>
                      <div className="edit-input">
                        <Field
                          type="text"
                          name="name"
                          className={`edit-name-input form-control ${
                            touched.name && errors.name ? "error" : null
                          }`}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error-message"
                        />
                        <div className="login-submit">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="save-btn mx-1"
                          >
                            <i className="bi bi-check2 px-2"></i> Save
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelClick}
                            className="cancel-btn mx-1"
                          >
                            <i className="bi bi-x-lg px-2"></i> Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
          <div className="info-row user-email">
            <span className="info-label">Email:</span>
            <span className="info-data">{currentUser.profile.email}</span>
          </div>
          <div className="info-row activation-date">
            <span className="info-label">Activation date:</span>
            <span className="info-data">{userInfos.createdAt}</span>
          </div>
        </div>
        <div>
          {!resetpwd && (
            <div className="row">
              <div className="col-12 col-sm-4">
                <button className="reset-btn" onClick={handleReset}>
                  Reset Password
                </button>
              </div>
              <div className="col-12 col-sm-8 d-flex align-items-end">
                {succmsg && result && (
                  <div className="response-msg success-msg">
                    <i className="bi bi-key-fill"></i>
                    <h6>Password updated successfully</h6>
                  </div>
                )}
              </div>
            </div>
          )}
          {resetpwd && (
            <div>
              <button className="reset-white-btn" onClick={handleReset}>
                Reset Password
              </button>

              <ResetPasswordForm
                setResetpwd={setResetpwd}
                setSuccmsg={showSuccessMsg}
                setResult={setResult}
                handleReset={handleReset}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

const convertTimestampToReadable = timestamp => {
  if (timestamp)
    return new Date(Number(timestamp)).toLocaleString("fr", {
      dateStyle: "short",
    })

  return null
}

export default AccountMain
