import React, { Fragment, useState } from "react"
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar"
import { slide as Slide } from "react-burger-menu"
import Icon from "../icon"

import "./index.scss"
const Sidebar = props => {
  const { sideBarItemsList } = props

  const [displayed, setDisplayed] = useState(sideBarItemsList?.main[0]?.display)

  return {
    displayed,
    setDisplayed,
    Render: (refreshIntervalId = null) => {
      return (
        <Fragment>
          <div className="d-block d-lg-non justify-content-center">
            <div className="sideBar" collapsed={0} toggled={0}>
              <Slide right>
                {sideBarItemsList?.main?.map((sideItem, index) => (
                  <a
                    key={index}
                    active={displayed === sideItem.display ? 1 : 0}
                    onClick={() => {
                      sideItem.onClick instanceof Function && sideItem.onClick()

                      sideItem.component &&
                        sideItem.display &&
                        setDisplayed(sideItem.display)
                    }}
                  >
                    <Icon size={30} color="#4b4b4d" icon={sideItem.icon} />
                    {sideItem.name}
                  </a>
                ))}
                {sideBarItemsList?.footer?.map((sideItem, index) => (
                  <a
                    key={index}
                    className="menu-item"
                    active={displayed === sideItem.display ? 1 : 0}
                    onClick={() => {
                      sideItem.name?.toLowerCase() === "logout" &&
                        refreshIntervalId &&
                        clearInterval(refreshIntervalId)

                      sideItem.onClick instanceof Function && sideItem.onClick()

                      sideItem.component &&
                        sideItem.display &&
                        setDisplayed(sideItem.display)
                    }}
                  >
                    <Icon size={30} color="#4b4b4d" icon={sideItem.icon} />
                    {sideItem.name}
                  </a>
                ))}
              </Slide>
            </div>
          </div>
          <div className=" d-none d-xl-bloc sideContent col-lg-3 col-md-3">
            <div className="sideBar">
              <ProSidebar collapsed={0} toggled={0} breakPoint="md">
                <SidebarContent>
                  <Menu iconShape="circle">
                    {sideBarItemsList?.main?.map((sideItem, index) => (
                      <MenuItem
                        key={index}
                        active={displayed === sideItem.display ? 1 : 0}
                        icon={
                          <Icon
                            size={30}
                            color="#4b4b4d"
                            icon={sideItem.icon}
                          />
                        }
                        onClick={() => {
                          sideItem.onClick instanceof Function &&
                            sideItem.onClick()

                          sideItem.component &&
                            sideItem.display &&
                            setDisplayed(sideItem.display)
                        }}
                      >
                        {sideItem.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </SidebarContent>
                <SidebarFooter>
                  <Menu iconShape="circle">
                    {sideBarItemsList?.footer?.map((sideItem, index) => (
                      <MenuItem
                        key={index}
                        active={displayed === sideItem.display ? 1 : 0}
                        icon={
                          <Icon
                            size={30}
                            color="#4b4b4d"
                            icon={sideItem.icon}
                          />
                        }
                        onClick={() => {
                          sideItem.name?.toLowerCase() === "logout" &&
                            refreshIntervalId &&
                            clearInterval(refreshIntervalId)

                          sideItem.onClick instanceof Function &&
                            sideItem.onClick()

                          sideItem.component &&
                            sideItem.display &&
                            setDisplayed(sideItem.display)
                        }}
                      >
                        {sideItem.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </SidebarFooter>
              </ProSidebar>
            </div>
          </div>
        </Fragment>
      )
    },
  }
}
export default Sidebar
