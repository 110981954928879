import * as _ from "lodash"
import Swal from "sweetalert2"
import React, { useState, useEffect, createContext } from "react"

import Sidebar from "./sidebar"
import service from "../../../services"
import { InitFCM } from "../notifications"
import LayoutGray from "../dashboard-layout/layout"
import ConsoleHeader from "../dashboard-layout/header"
import { handleFCM } from "../notifications/fcm-actions"
import {
  getFromStorage,
  readFromStorage,
  setToStorage,
} from "../../../services/utils"
import {
  getComponent,
  consoleSideBarItemsList,
} from "./sidebar/sidebar-entries"

import "./index.scss"
const MembershipScreen = ({ path }) => {
  const {
    Render: RenderSideBar,
    displayed,
    setDisplayed,
  } = Sidebar({
    sideBarItemsList: consoleSideBarItemsList,
  })

  const Component = getComponent(consoleSideBarItemsList, displayed)

  const [profile, setProfile] = useState(null)
  const [refreshIntervalId, setRefreshIntervalId] = useState(null)
  const [currentToken, setCurrentToken] = useState(null)

  useEffect(() => {
    currentToken && handleFCM(updateCurrentUserData)
    if (!profile) {
      readFromStorage(({ profile }) => {
        setProfile(profile)
        if (!currentToken) {
          const intervalId = setInterval(async () => {
            console.info("checking for user updates...")
            const res = await service.db.getUSer(profile.id)
            updateCurrentUserData(res)
          }, 1500)

          setRefreshIntervalId(intervalId)
        }
      })
    }

    currentToken && refreshIntervalId && clearInterval(refreshIntervalId)

    let successfulStripeCheckoutParam = false
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search)
      successfulStripeCheckoutParam = params.get("successfulStripeCheckout")
    }
    successfulStripeCheckoutParam && successAlert()

    return () => {}
  }, [currentToken, profile])

  const updateCurrentUserData = (profile = null) => {
    const profileString = getFromStorage("profile")
    const profileLS = JSON.parse(profileString || "{}")

    if (
      !_.isEqualWith(profileLS, profile, _.isMatch) ||
      !_.isEqualWith(profile, profileLS, _.isMatch)
    ) {
      if (Object.keys(profile || {}).length) {
        console.log("updating current user's data...")
        setProfile(profile)
        setToStorage("profile", profile)
      }
    }
  }

  return (
    profile && (
      <LayoutGray>
        <ProfileContext.Provider value={profile}>
          <InitFCM profile={profile} setCurrentToken={setCurrentToken} />
          <ConsoleHeader
            refreshIntervalId={refreshIntervalId}
            setDisplayed={setDisplayed}
          />

          <div id="ConsoleLayout" className="ConsoleLayout d-flex">
            {RenderSideBar(refreshIntervalId)}

            <div className="consoleContent  col-lg-9  col-md-9  ">
              {/* <Membership /> */}
              {Component ? (
                <Component />
              ) : (
                <>component not found : {displayed}</>
              )}
            </div>
          </div>
        </ProfileContext.Provider>
      </LayoutGray>
    )
  )
}

export const ProfileContext = createContext(null)

const successAlert = () => {
  Swal.fire({
    title: "Awesome!",
    text: "Your payment was successful.",
    icon: "success",
  })
    .then(function () {
      window.history.replaceState({}, document.title, "/console")
    })
    .catch(() => {
      console.error("error editing page url")
    })
}

export default MembershipScreen
