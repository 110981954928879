import React from "react"
import "./index.scss"
const AccountHeader = () => {
  return (
    <div
      id="AccountHeader"
      className="AccountHeader"
      data-testid="AccountHeader"
    >
      <img src="/account-user.svg" alt="user-icon" />
      <span className="account-info" data-testid="account-title">
        Account Informations
      </span>
    </div>
  )
}

export default AccountHeader
