import React from "react"
import { navigate } from "gatsby"

import Seo from "../../../seo"
import LoginForm from "./login-form"
import LoginLayout from "../../../layout/login-layout"

import "./index.scss"
import { Helmet } from "react-helmet"
function Login({ imgSrc = "/login.svg" }) {
  const handleNavigation = () => {
    navigate("/console/signup/")
  }

  return (
    <LoginLayout>
      <Seo
        title="Login"
        description="Login to your account and discover all Maximo IBM features"
      />
      <Helmet prepend>
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/login.jpg"
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/login.jpg"
        />
      </Helmet>
      <div id="Login" className=" login ">
        <h1>Login To Your Account</h1>

        <div className=" d-flex justify-content-center ">
          <div className="box col-12  row">
            {imgSrc && (
              <>
                <div className="SectionOfImages col-6 d-none d-lg-block ">
                  <div className="text-center">
                    <img
                      alt="MAXapps: login"
                      title="MAXapps: login"
                      src={imgSrc}
                      className="loginImg-style"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="form-section col-12 col-lg-6">
              <div className="row justify-content-xl-end  pb-4">
                <div className="text-center text-lg-end  col-12  col-lg-6 col-xl-7   py-2">
                  <p>Don't have an account ?</p>
                </div>
                <div className="text-center  text-lg-end  col-12  col-lg-5 ">
                  <button
                    type="button"
                    className="form-button register-button "
                    onClick={handleNavigation}
                  >
                    Sign up
                  </button>
                </div>
              </div>
              <div className="pt-4 ">
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default Login
