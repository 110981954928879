import React, { useContext, useState } from "react"
import { nanoid } from "nanoid"

import services from "../../../../../services"
import { ProfileContext } from "../../../dashboard/membership-layout"

const AddHostForm = ({ setShowAddHostForm }) => {
  const currentUser = useContext(ProfileContext)

  const [formError, setFormError] = useState(false)
  const [formData, setFormData] = useState(initHost)

  const handleChange = event => {
    event.preventDefault()

    const fieldName = event.target.getAttribute("name")
    const fieldValue = event.target.value

    const newData = { ...formData }
    newData[fieldName] = fieldValue

    setFormData(newData)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const hostName = formData.name.replaceAll(" ", "")
    if (!hostName) {
      setFormError(true)
      return
    }

    const userHosts = (await services.db.getUSer(currentUser.profile.email))
      .hosts
    const hostExist = userHosts.find(userHost => {
      return userHost.name.toUpperCase() === hostName.toUpperCase()
    })

    if (hostExist) {
      setFormError(true)
      return
    }

    const newHost = {
      id: nanoid(),
      name: hostName,
      code: hostName + "-" + currentUser.licenceKey,
      domainIP: formData.domainIP,
      port: formData.port,
      ssl: !formData.protocole ? false : true,
      createdDate: Date.now(),
    }

    const newHosts = [...userHosts]
    newHosts.push(newHost)

    await services.db.addClientHost(newHost, currentUser.profile.email)
    const res = await services.db.updateUserHosts(currentUser, newHosts)
    if (res.success) {
      setFormData(initHost)
      setShowAddHostForm(false)
      setFormError(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="form-input p-3">
      <div className="form-group row p-2">
        <p className="modal-title"> Add new host</p>
      </div>
      <div className="form-group row p-2">
        <label
          htmlFor="Name"
          className="col-sm-3 col-4 col-md-3 px-3 col-form-label"
        >
          Name
        </label>
        <div className="col-sm-9 col-8 col-md-9 px-2">
          <input
            type="text"
            name="name"
            className="form-control input-name"
            required="required"
            maxLength="3"
            placeholder="Name"
            onChange={handleChange}
          />
          {formError && (
            <label htmlFor="error" className="text-danger">
              <small> Name should be unique and not empty.</small>
            </label>
          )}
        </div>
      </div>
      <div className="form-group row p-2">
        <label
          htmlFor=" Domain/IP"
          className="col-sm-3 col-4 col-md-3 px-3 col-form-label"
        >
          Domain/IP
        </label>
        <div className="col-sm-9 col-8 col-md-9 px-2">
          <input
            type="text"
            name="domainIP"
            className="form-control"
            required="required"
            placeholder="Domain/IP"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group row p-2">
        <label
          htmlFor="Port"
          className="col-sm-3 col-4 col-md-3 px-3 col-form-label"
        >
          Port
        </label>
        <div className="col-sm-9 col-8 col-md-9 px-2">
          <input
            type="text"
            className="form-control"
            name="port"
            placeholder="Port"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group row p-2">
        <label
          htmlFor="Ssl"
          className="col-sm-3 col-4 col-md-3 px-3 col-form-label"
        >
          Ssl
        </label>
        <div className="col-sm-9 col-8 col-md-9 px-2">
          <select
            className="protocole-select form-control"
            name="protocole"
            defaultValue="http"
            onChange={handleChange}
          >
            <option value="http">http</option>
            <option value="https">https</option>
          </select>
        </div>
      </div>

      <div className="form-group row p-3">
        <div className="col-sm-6 col-6 ">
          <button
            onClick={() => {
              setShowAddHostForm(false)
            }}
            className="cancel-host-btn"
          >
            <i className="bi bi-x-lg px-2"></i> cancel
          </button>
        </div>
        <div className="col-sm-6 col-6 px-2 text-end">
          <button type="submit" className="add-host-btn ">
            <i className="bi bi-save  px-2"></i> Add
          </button>
        </div>
      </div>
    </form>
  )
}

const initHost = {
  name: "",
  code: "",
  domainIP: "",
  port: "",
  ssl: false,
}

export default AddHostForm
