import React from "react"

const FormCheque = props => {
  const { type = "" } = props
  return (
    <div className="second-step m-2 px-md-4">
      <div className="detail p-2 detail-margin ">
        <div className="row p-3 pb-3 pt-2"></div>
        <div className=" py-1">
          <p className=" ps-1 order-val">{type?.toUpperCase()}</p>
        </div>
        <p className="ps-2 pur-accounts">Complete missing informations</p>
      </div>
      <div className="p-3 order-step2 row ">
        <form>
          <div className="form-group row">
            <label htmlFor="Label" className="col-sm-2 col-form-label">
              Label
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="Label"
                className="form-control"
                placeholder="Value"
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Label" className="col-sm-2 col-form-label">
              Label
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="Label"
                className="form-control"
                placeholder="Value"
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Label" className="col-sm-2 col-form-label">
              Label
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="Label"
                className="form-control"
                placeholder="Value"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FormCheque
