import React from "react"
import AccountHeader from "./card-header"
import AccountMain from "./card-main"

const Account = () => {
  return (
    <div id="Account" className="Account" data-testid="Account">
      <AccountHeader />
      <AccountMain />
    </div>
  )
}

export default Account
