import React from "react"
import { Router } from "@reach/router"

import ProtectedRoutes from "../components/private-route"
import Login from "../components/console/authentication/login"
import Verification from "../components/console/authentication/verification"
import ResetPwd from "../components/console/authentication/reset-pwd"
import ResendLink from "../components/console/authentication/broken-verification"
import Register from "../components/console/authentication/register"
import Redirection from "../components/redirection"
import MembershipScreen from "../components/console/dashboard/membership-layout"
import AccountScreen from "../components/console/dashboard/account-layout"
import EmailValidation from "../components/console/authentication/validation"
import ConfirmMails from "../components/console/confirm-mails"

const App = () => (
  <Router>
    <ProtectedRoutes
      shouldBeConnected={true}
      path="/console/"
      component={MembershipScreen}
    />
    <ProtectedRoutes
      shouldBeConnected={true}
      path="/console/account/"
      component={AccountScreen}
    />
    <ProtectedRoutes
      shouldBeConnected={false}
      path="/console/login/"
      component={Login}
      imgSrc="/login.svg"
    />
    <ProtectedRoutes
      shouldBeConnected={false}
      path="/console/signup/"
      component={Register}
      imgSrc="/new.svg"
    />
    <ProtectedRoutes
      shouldBeConnected={false}
      path="/console/forgot-pwd/"
      component={ResendLink}
    />
    <ProtectedRoutes
      shouldBeConnected={false}
      path="/console/validation/"
      component={EmailValidation}
    />
    <ProtectedRoutes
      shouldBeConnected={false}
      path="/console/update-pwd/"
      component={ResetPwd}
    />
    <ProtectedRoutes
      shouldBeConnected={false}
      path="/console/welcome-msg/"
      component={Verification}
    />
    <ProtectedRoutes
      shouldBeConnected={true}
      path="/console/admin/"
      component={ConfirmMails}
    />

    <Redirection path="/console/redirection" />
  </Router>
)

export default App
