import axios from "axios"
import React, { useState, useContext, useEffect } from "react"
import { nanoid } from "nanoid"
import ReactPaginate from "react-paginate"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import { Modal } from "react-bootstrap"

import ReadOnlyRow from "./ReadOnlyRow"
import AddHostForm from "./AddHostForm"
import services from "../../../../../services"
import { ProfileContext } from "../../../dashboard/membership-layout"
import Documentation from "../documentation"
import HostOrderStep1 from "../host-order/host-order-step1"
import HostOrderStep2 from "../host-order/host-order-step2"
import Pay2 from "../../../payment/pay2"
import Icon from "../../../dashboard/icon"

import "./index.scss"
import LottieAnimation from "../../../../../animations/lottie"
const DomainTable = () => {
  let count = 1,
    unitPrice = 49,
    type = "host"
  const [currentStep, setCurrentStep] = useState(1)
  const [selectedCard, setselectedCard] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [showError, setShowError] = useState(null)
  const currentUser = useContext(ProfileContext)
  let currentUsersorted = currentUser?.hosts
    ? currentUser.hosts.sort((a, b) => b.createdDate - a.createdDate)
    : []
  const perPage = 3
  const [generatingHost, setGeneratingHost] = useState(false)
  const [showAddHostForm, setShowAddHostForm] = useState(false)
  const [offset, setOffset] = useState(0)
  const [pagistate, setPagistate] = useState({
    selectedPage: 1,
    pageCount: Math.ceil(currentUsersorted.length / perPage),
  })
  const [purchaseData, setPurchaseData] = useState()

  const [data, setData] = useState(
    currentUsersorted.slice(offset, offset + perPage)
  )

  const handlePageClick = event => {
    setPagistate({ ...pagistate, selectedPage: event.selected })
    const newOffset = (event.selected * perPage) % currentUsersorted.length
    setOffset(newOffset)

    setData(currentUsersorted.slice(newOffset, newOffset + perPage))
  }

  const handleGenerateHost = () => {
    setShowModal(true)
  }
  const handleCheckout = () => {
    typeof window !== "undefined" && localStorage.removeItem("currentPurchase")
    payDirectly()
  }

  const payDirectly = async () => {
    const purchaseLs = {
      count,
      type,
      price: unitPrice,
    }
    setPurchaseData(purchaseLs)

    // pay(purchaseLs)
    setCurrentStep(currentStep + 1)
  }
  const handleGenerateFreeHost = async () => {
    setGeneratingHost(true)

    let res = {
      success: false,
      content: null,
      error: null,
    }
    const serverName = makeid(3)
    const serverCodeTag = `${serverName}-${currentUser?.licenceKey}`
    const data = { AWSnameTag: serverCodeTag }

    try {
      const response = await axios.post(
        `https://us-central1-imaxproject-c13a5.cloudfunctions.net/generateAWServer`,
        { data },
        {
          headers: {
            authorization: `Bearer ${services.auth.auth.currentUser.stsTokenManager.accessToken}`,
          },
        }
      )
      if (response.data?.success) {
        const newHost = {
          id: nanoid(),
          name: serverName,
          code: serverCodeTag,
          domainIP: response.data.content.publicIp,
          port: response.data.content.port,
          ssl: false,
          createdDate: Date.now(),
          awsConfig: {
            instanceId: response.data.content.globalId,
            instanceData: response.data.content.instanceData,
            status: "pending",
          },
        }

        const userHosts = [...currentUsersorted]
        userHosts.push(newHost)

        await services.db.addClientHost(newHost, currentUser?.profile.email)
        await services.db.updateUserHosts(currentUser, userHosts)
        await services.db.addAWSserver(response.data.content.globalId, {
          instanceId: response.data.content.globalId,
          ownerId: currentUser?.authID,
        })

        res.success = true
        res.error = "Error while generating the new host."
      } else {
        console.error(response.data?.error)
        res.error = "Error while generating the new host."
      }
    } catch (error) {
      console.error(error)
      res.error = "Error while generating the new host."
    }
    setGeneratingHost(false)
    return res
  }

  useEffect(() => {
    setData(currentUsersorted.slice(offset, offset + perPage))
    setPagistate({
      ...pagistate,
      pageCount: Math.ceil(currentUsersorted.length / perPage),
    })

    return () => {}
  }, [currentUser])

  return currentUser ? (
    <div className="DomainTable">
      <div className="table-heading ">
        <div className="table-title-container">
          <span className="table-title">
            <Icon
              color="#6f53a3"
              icon={"host"}
              alt="maxapps maximo: hosts-icon"
              title="maxapps maximo: hosts-icon"
            />
            Hosts
          </span>
        </div>
        <div className="text-end d-flex">
          <button
            disabled={generatingHost}
            className="generate-host-btn"
            onClick={handleGenerateHost}
            data-testid="purchase-btn"
          >
            <span>{generatingHost ? "Generating" : "Generate Host"}</span>
            {generatingHost && (
              <div className="leftMargin">
                <LottieAnimation height={25} width={25} />
              </div>
            )}
          </button>
          <button
            className="add-host-btn"
            onClick={() => {
              setShowAddHostForm(true)
            }}
            data-testid="purchase-btn"
          >
            <span> New Host</span>
          </button>
        </div>
      </div>
      <table className="hostTable">
        <thead>
          <tr className="white-border">
            <th>Name </th>
            <th>Code </th>
            <th>Domain/IP </th>
            <th>Port </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((host, index) => (
            <React.Fragment key={index}>
              <ReadOnlyRow item={host} setOffset={setOffset} />
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <ToastContainer />

      {currentUser.hosts?.length > 0 ? (
        <div className="table-pagination">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={".."}
            breakClassName={"break-me"}
            pageCount={pagistate.pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <div className="no-items text-center py-2">
          <h6>No items to display</h6>
        </div>
      )}

      <Documentation />
      <Modal
        dialogClassName="newhost-modal"
        show={showAddHostForm}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <AddHostForm setShowAddHostForm={setShowAddHostForm} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal}
        dialogClassName="generate-newhost-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {currentStep === 3 && (
            <div className=" text-end">
              <i
                onClick={() => {
                  setShowModal(false)
                  setselectedCard(1)
                  setTimeout(() => {
                    setCurrentStep(1)
                  }, 1000)
                }}
                className="bi bi-x-lg  "
              ></i>
            </div>
          )}
          {currentStep === 1 && (
            <HostOrderStep1
              setselectedCard={setselectedCard}
              generateFreeHost={handleGenerateFreeHost}
            />
          )}
          {currentStep === 2 && <HostOrderStep2 />}
          {currentStep === 3 && <Pay2 data={purchaseData} />}
          <div className="row p-3">
            {showError && (
              <div className="col-12 generate-error-msg">{showError}</div>
            )}
            {currentStep !== 3 && (
              <div className="col-4 text-start ">
                <button
                  className="cancel-host-btn"
                  onClick={() => {
                    setShowModal(false)
                    setselectedCard(1)
                    setTimeout(() => {
                      setCurrentStep(1)
                    }, 1000)
                  }}
                >
                  <i className="bi bi-x-lg  pe-2"></i> Cancel
                </button>
              </div>
            )}

            {currentStep === 1 && (
              <div className="col-8 text-end ">
                <button
                  className="add-host-btn"
                  onClick={
                    selectedCard === 1
                      ? async e => {
                          const res = await handleGenerateFreeHost()
                          console.log("====================================")
                          console.log("generate res: ", res)
                          console.log("====================================")
                          if (res.success) {
                            setShowModal(false)
                          } else {
                            setShowError(res.error)
                            setTimeout(() => {
                              setShowError(null)
                            }, 5000)
                          }
                        }
                      : e => {
                          setCurrentStep(2)
                        }
                  }
                >
                  Proceed <i className="bi bi-arrow-right  ps-2"></i>
                </button>
              </div>
            )}
            {currentStep === 2 && (
              <div className="col-8 d-flex justify-content-end  ">
                <div className="row">
                  <div className="col-4  me-2 puchase-amount">1325$</div>

                  <button
                    className="col-8 ms-3 purchase-host-btn"
                    onClick={handleCheckout}
                    // disabled={isSubmitted}
                  >
                    <i className="bi bi-cart-dash pe-2  "></i> Purchase
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  ) : (
    <></>
  )
}

function makeid(length = 4) {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export default DomainTable
