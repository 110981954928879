import React from "react"
import { navigate } from "gatsby"

import ResendlinkForm from "./resendlink-form"

import "./index.scss"
import LoginLayout from "../../../layout/login-layout"
import Seo from "../../../seo"
function ResendLink() {
  const isForgotPwd =
    typeof window !== "undefined" &&
    window.location.pathname.includes("forgot-pwd")
  const handleNavigation = () => {
    navigate("/console/login/")
  }
  return (
    <LoginLayout>
      <Seo title="Broken Verification" />

      <div id="ResendLink" className=" ResendLink ">
        <div className=" d-flex justify-content-center ">
          <div className="box col-12 row">
            {/* <div className="d-flex justify-content-center ">
              <div className=" logoImg d-block d-lg-none">
                <img src="/maxapps-logo.svg" />
              </div>
             </div> */}
            <div className="SectionOfImages col-6 d-none d-lg-block ">
              <div className="text-center">
                <img
                  alt="MAXapps: Broken Verification "
                  title="MAXapps: Broken Verification "
                  src="/error.svg"
                  className="loginImg-style"
                />
              </div>
            </div>

            <div className="form-section col-12 col-lg-6">
              <div className="row  pb-4">
                <div className="text-center text-lg-end  col-12   col-xl-6 col-xxl-8   py-2"></div>
                <div className="text-center  text-lg-end  col-12  col-xl-3 col-xxl-3">
                  <button
                    type="button"
                    className="form-button register-button"
                    onClick={handleNavigation}
                  >
                    Login
                  </button>
                </div>
              </div>
              <div className="d-flex flex-row pt-4">
                {!isForgotPwd && (
                  <>
                    <img
                      alt="MAXapps: Broken Verification "
                      title="MAXapps: Broken Verification "
                      src="/close.svg"
                      className="icon"
                    />
                    <p className="desc"> Broken Verification Link</p>
                  </>
                )}
              </div>
              <h3 className="title pt-4">
                {!isForgotPwd ? "Resend Link" : "Reset Password"}
              </h3>

              <div className="pt-4 ">
                <ResendlinkForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default ResendLink
