import React from "react"
import "./index.scss"

const OrderStep2 = ({ type, selectedItem, setSelectedItem }) => {
  return (
    <>
      <div className=" second-step  px-md-4">
        <div className="detail  detail-margin ">
          <div className="row p-3 pb-3 pt-4"></div>
          <div className=" py-1">
            <p className=" ps-2 order-val">{type.toUpperCase()}</p>
          </div>
          <p className="ps-3 pur-accounts">Select payment method</p>

          <div className=" py-2 order-step2 row ">
            <button
              className={
                selectedItem === 1
                  ? "check-form-selected cardpurchase card1  d-grid justify-content-center  col-6  col-md-4 col-lg-4  col-sm-4"
                  : "check-form d-grid cardpurchase card1  justify-content-center  col-6  col-md-4 col-lg-4 col-sm-4"
              }
              onClick={() => {
                setSelectedItem(1)
              }}
            >
              <div className="p-1">
                <div className="cheakBox d-grid align-items-center">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 113 113"
                    fill="#AEAEAE"
                  >
                    <g
                      id="noun_cheque_3948542"
                      transform="translate(-4.363 -1)"
                    >
                      <g id="Group_157" transform="translate(4.363 1)">
                        <path
                          id="Path_295"
                          d="M56.5,82.9h-49V41.4h37.7c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8H3.8c-2.1,0-3.8,1.7-3.8,3.8
			c0,0,0,0,0,0v49c0,2.1,1.7,3.8,3.8,3.8c0,0,0,0,0,0h52.7c2.1,0,3.8-1.7,3.8-3.8C60.3,84.6,58.6,82.9,56.5,82.9L56.5,82.9z"
                        />
                        <path
                          id="Path_296"
                          d="M90.4,15.1v-3.8C90.4,5.1,85.3,0,79.1,0c-6.2,0-11.3,5.1-11.3,11.3v3.8c-2.1,0-3.8,1.7-3.8,3.8c0,0,0,0,0,0
			c-6.2,0-11.3,5.1-11.3,11.3v22.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8V30.1c0-2.1,1.7-3.8,3.8-3.8v60.3c0,0,0,0.1,0,0.1
			c0,0.4,0.1,0.8,0.2,1.2c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0.1,0,0.1l11.3,22.6c0.9,1.9,3.2,2.6,5.1,1.7c0.7-0.4,1.3-1,1.7-1.7
			l11.3-22.6c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2c0,0,0-0.1,0-0.1V18.8C94.2,16.8,92.5,15.1,90.4,15.1
			C90.4,15.1,90.4,15.1,90.4,15.1z M75.3,11.3c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8v3.8h-7.5V11.3z M79.1,100.8l-5.2-10.4h10.4
			L79.1,100.8z M86.6,82.9H71.6V22.6h15.1V82.9z"
                        />
                        <path
                          id="Path_297"
                          d="M18.8,52.7c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8h18.8c2.1,0,3.8-1.7,3.8-3.8
			c0-2.1-1.7-3.8-3.8-3.8H18.8z"
                        />
                        <path
                          id="Path_298"
                          d="M56.5,67.8H18.8c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h37.7c2.1,0,3.8-1.7,3.8-3.8S58.6,67.8,56.5,67.8z"
                        />
                        <path
                          id="Path_299"
                          d="M109.2,33.9h-7.5c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8l0,0h3.8v41.4h-3.8c-2.1,0-3.8,1.7-3.8,3.8
			c0,2.1,1.7,3.8,3.8,3.8h7.5c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0v-49C113,35.6,111.3,33.9,109.2,33.9
			C109.2,33.9,109.2,33.9,109.2,33.9z"
                        />
                      </g>
                    </g>
                  </svg>
                  <h6 className="text-center">Cheque</h6>
                </div>
              </div>
            </button>
            <button
              className={
                selectedItem === 2
                  ? "check-form-selected cardpurchase card2 d-grid justify-content-center  col-6  col-md-4 col-lg-4 col-sm-4"
                  : "check-form d-grid cardpurchase card2 justify-content-center  col-6  col-md-4 col-lg-4 col-sm-4"
              }
              onClick={() => {
                setSelectedItem(2)
              }}
            >
              <div className="p-1">
                <div className="cheakBox d-grid align-items-center">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 122.7 88.6"
                    fill="#AEAEAE"
                  >
                    <g id="Group_156" transform="translate(45 -9918)">
                      <g
                        id="noun_bank_card_943995"
                        transform="translate(-45 9918)"
                      >
                        <path
                          id="Path_289"
                          d="M112.5,13.6H92v-3.4C92,4.6,87.4,0,81.8,0H10.2C4.6,0,0,4.6,0,10.2v54.5C0,70.4,4.6,75,10.2,75h20.4v3.4
			c0,5.6,4.6,10.2,10.2,10.2h71.6c5.6,0,10.2-4.6,10.2-10.2V23.9C122.7,18.2,118.1,13.6,112.5,13.6z M10.2,68.2
			c-1.9,0-3.4-1.5-3.4-3.4V10.2c0-1.9,1.5-3.4,3.4-3.4h71.6c1.9,0,3.4,1.5,3.4,3.4v54.5c0,1.9-1.5,3.4-3.4,3.4L10.2,68.2z
			 M115.9,78.4c0,1.9-1.5,3.4-3.4,3.4H40.9c-1.9,0-3.4-1.5-3.4-3.4V75h44.3C87.4,75,92,70.4,92,64.8V40.9h23.9V78.4z M115.9,27.3H92
			v-6.8h20.4c1.9,0,3.4,1.5,3.4,3.4L115.9,27.3z"
                        />
                        <path
                          id="Path_290"
                          d="M23.9,47.7H17c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h6.8c1.9,0,3.4-1.5,3.4-3.4S25.7,47.7,23.9,47.7z"
                        />
                        <path
                          id="Path_291"
                          d="M40.9,47.7h-6.8c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h6.8c1.9,0,3.4-1.5,3.4-3.4S42.8,47.7,40.9,47.7z"
                        />
                        <path
                          id="Path_292"
                          d="M57.9,47.7h-6.8c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4l0,0h6.8c1.9,0,3.4-1.5,3.4-3.4
			S59.8,47.7,57.9,47.7z"
                        />
                        <path
                          id="Path_293"
                          d="M75,47.7h-6.8c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4H75c1.9,0,3.4-1.5,3.4-3.4S76.9,47.7,75,47.7z"
                        />
                        <path
                          id="Path_294"
                          d="M17,40.9h17c1.9,0,3.4-1.5,3.4-3.4c0,0,0,0,0,0V23.9c0-1.9-1.5-3.4-3.4-3.4c0,0,0,0,0,0H17
			c-1.9,0-3.4,1.5-3.4,3.4c0,0,0,0,0,0v13.6C13.6,39.4,15.2,40.9,17,40.9L17,40.9z M20.4,27.3h10.2v6.8H20.4L20.4,27.3z"
                        />
                      </g>
                    </g>
                  </svg>
                  <h6 className="text-center">Carte</h6>
                </div>
              </div>
            </button>
            <button
              className={
                selectedItem === 3
                  ? "check-form-selected cardpurchase card3 d-grid justify-content-center col-6  col-md-4 col-lg-4 col-sm-4"
                  : "check-form d-grid cardpurchase card3 justify-content-center col-6  col-md-4 col-lg-4 col-sm-4"
              }
              onClick={() => {
                setSelectedItem(3)
              }}
            >
              <div className="p-1">
                <div className="cheakBox d-grid align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="76.474"
                    height="36.298"
                    viewBox="0 0 76.474 36.298"
                  >
                    <g
                      id="noun-wire-transfer-676260"
                      transform="translate(-179.252 117.413)"
                    >
                      <path
                        id="Path_311"
                        data-name="Path 311"
                        d="M181.572,181.349a2.431,2.431,0,0,1-.62.8l-7.968,7.879a2.038,2.038,0,0,1-2.744,0,1.874,1.874,0,0,1,0-2.744l4.78-4.78h-15.4a2.036,2.036,0,1,1,0-4.072h15.493l-4.869-4.78a1.874,1.874,0,0,1,0-2.744,2.038,2.038,0,0,1,2.744,0l8.233,8.145c.088.088.088.177.177.266a2.39,2.39,0,0,1,.177,2.035ZM163.6,174.533a2,2,0,0,1-2.036-2.036V167.45a.983.983,0,0,0-.974-.974H126.416a.983.983,0,0,0-.974.974V172.5a2.036,2.036,0,1,1-4.072,0V164.44a2,2,0,0,1,2.036-2.036h40.282a2,2,0,0,1,2.036,2.036V172.5a2.138,2.138,0,0,1-2.125,2.036Zm-51.7,4.072H127.39a2.036,2.036,0,0,1,0,4.072h-15.4l4.78,4.78a1.874,1.874,0,0,1,0,2.744,2.038,2.038,0,0,1-2.744,0l-7.968-7.879a2.034,2.034,0,0,1-.443-2.744c.088-.088.088-.177.177-.266l8.233-8.145a2.038,2.038,0,0,1,2.744,0,1.874,1.874,0,0,1,0,2.744Zm37.626,5.4c0,2.479-1.682,4.073-4.78,4.338v1.239a1.1,1.1,0,0,1-1.151,1.062,1.023,1.023,0,0,1-1.062-1.062v-1.239a6.725,6.725,0,0,1-4.869-2.656,1.647,1.647,0,0,1-.177-.62,1.21,1.21,0,0,1,1.239-1.239,1.38,1.38,0,0,1,1.062.531,5.318,5.318,0,0,0,3.9,1.682c2.125,0,3.187-.708,3.187-2.036,0-3.276-8.588-1.771-8.588-7.171,0-1.948,1.328-3.718,4.338-3.984v-1.239a1.076,1.076,0,0,1,1.062-1.062,1.1,1.1,0,0,1,1.151,1.062v1.328a6.449,6.449,0,0,1,4.249,2.036,1,1,0,0,1,.266.708,1.193,1.193,0,0,1-1.239,1.151,1.363,1.363,0,0,1-.974-.531,4.738,4.738,0,0,0-3.276-1.151c-1.948,0-2.656.886-2.656,1.77-.266,2.833,8.322,1.506,8.322,7.083Zm-26.117,2.568a2,2,0,0,1,2.036,2.036v5.047a.983.983,0,0,0,.974.974h34.262a.983.983,0,0,0,.974-.974v-5.047a2.036,2.036,0,0,1,4.072,0v8.057a2,2,0,0,1-2.036,2.036H123.405a2,2,0,0,1-2.036-2.036v-8.057a2,2,0,0,1,2.036-2.036Z"
                        transform="translate(74 -279.817)"
                        fill-rule="evenodd"
                      />
                    </g>
                  </svg>
                  <h6 className="text-center">Wire</h6>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderStep2
