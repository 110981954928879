import React from "react"
import LayoutGray from "../dashboard-layout/layout"

import "./index.scss"
import Account from "../account"

const AccountScreen = ({ path }) => {
  return (
    <LayoutGray>
      <div id="ConsoleLayout" className="ConsoleLayout">
        <div className="row ">
          <div className=" consoleContent col-xl-9">
            <Account />
          </div>
        </div>
      </div>
    </LayoutGray>
  )
}

export default AccountScreen
