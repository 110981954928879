import { navigate } from "gatsby"

import DomainTable from "../../membership/card-main/domain-table"
import LicenseTable from "../../membership/card-main/license-table"
import UsersTable from "../../membership/card-main/users-table"
import AccountMain from "../../account/card-main"
import Service from "../../../../services"
import ListUsers from "../../confirm-mails/list-users"
import ContactList from "../../contacts"

export const consoleSideBarItemsList = {
  main: [
    {
      name: "Licenses",
      icon: "key",
      display: "licenses",
      component: LicenseTable,
    },
    {
      name: "Hosts",
      icon: "host",
      display: "hosts",
      component: DomainTable,
    },
    {
      name: "Users",
      icon: "users",
      display: "users",
      component: UsersTable,
    },
  ],
  footer: [
    {
      name: "Account",
      icon: "customer",
      display: "account",
      component: AccountMain,
    },
    {
      name: "Logout",
      icon: "logout",
      onClick: async () => {
        await Service.auth.logOut()
        navigate("/")
      },
    },
    {
      name: "maxapps-maximo.com",
      icon: "website",
      onClick: () => {
        navigate("/")
      },
    },
  ],
}

export const adminSideBarItemsList = {
  main: [
    {
      name: "Accounts Approval",
      icon: "key",
      display: "approval",
      component: ListUsers,
    },
    {
      name: "Contacts",
      icon: "host",
      display: "contacts",
      component: ContactList,
    },
  ],
  footer: [
    {
      name: "Logout",
      icon: "logout",
      onClick: async () => {
        await Service.auth.logOut()
        navigate("/")
      },
    },
    {
      name: "maxapps-maximo.com",
      icon: "website",
      onClick: () => {
        navigate("/")
      },
    },
  ],
}

export const getComponent = (sideBarItemsList = {}, displayed = "") => {
  return Object.values(sideBarItemsList)
    .flat()
    .find(item => item.display === displayed)?.component
}
