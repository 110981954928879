const fcmActionList = []

const handleFCM = (action = () => {}) => {
  if (action instanceof Function) {
    const actionIndex = fcmActionList.indexOf(action)
    if (actionIndex != -1) {
      fcmActionList[actionIndex] = action
    } else {
      fcmActionList.push(action)
    }
  }
  return fcmActionList
}

export { fcmActionList, handleFCM }
