import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link, navigate } from "gatsby"
import { FiLogOut } from "react-icons/fi"
import Service from "../../../../services"

import "./index.scss"
import { ProfileContext } from "../../dashboard/membership-layout"
const ConsoleHeader = props => {
  const { _link, setDisplayed = () => {}, refreshIntervalId } = props
  // const [clicked, setclicked] = useState(true)
  const currentUser = useContext(ProfileContext)
  let info = {}
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/header-console/" }) {
          frontmatter {
            logoMobile
            logoWeb
            consoleLink
            consoleTitle
          }
        }
      }
    `)
    info = data.mdx.frontmatter
  } catch (error) {
    info.consoleLink = _link
  }

  const handleLogout = async () => {
    refreshIntervalId && clearInterval(refreshIntervalId)
    await Service.auth.logOut()
    localStorage.clear()
    navigate("/")
  }
  return (
    <div
      id="ConsoleHeader"
      className="d-flex flex-row ConsoleHeader"
      data-testid="ConsoleHeader"
    >
      <div className="ConsoleHeader d-flex flex-row col-11">
        <Link
          className="navbar-brand d-none d-lg-block"
          to="/"
          data-testid="link1"
        >
          <img
            alt="maximo maxapps"
            src={`${info?.logoWeb}`}
            className="p-0 m-0 imgMaxapps"
            data-testid="img1"
          />
        </Link>
        <Link
          className="navbar-brand d-block d-lg-none "
          to="/"
          data-testid="link2"
        >
          <img
            alt="maximo maxapps"
            src={info?.logoMobile}
            className="p-0 m-0 imgMaxappsMobile "
            data-testid="img2"
          />
        </Link>
        <Link
          className="docContainer  d-none d-lg-block me-md-3 "
          to="/console/"
        >
          <img
            alt="maximo maxapps"
            src={info?.consoleTitle}
            className=" imgMaxappsMobile "
          />
        </Link>
        <Link className="imgdocsMobile d-block d-lg-none" to="/console/">
          <img
            alt="maximo maxapps"
            src={info?.consoleTitle}
            className=" pt-1 imgMaxappsMobile "
          />
        </Link>
        <div className="nav-console-btn ">
          <p className="userName ">{currentUser.profile.displayName}</p>
        </div>
        <div className="dropdown">
          <button
            className="btn bg-transparent dropdownbtn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="bi bi-gear"></i>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button
                className="logout-btn "
                onClick={() => {
                  setDisplayed("account")
                }}
              >
                <i className="bi bi-person-lines-fill"></i>
                Account
              </button>
            </li>
            <li>
              <button className="logout-btn " onClick={handleLogout}>
                <FiLogOut size={20} />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-1 pt-2 console-list">
        {/* <button
          className="navbar-toggler d-block d-xl-non m-0 justify-content-center"
          id="react-burger-menu-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            setclicked(!clicked)
          }}
        >
          <span className={clicked ? "bi bi-list" : "bi bi-x-lg"}></span>
        </button> */}
      </div>
    </div>
  )
}

export default ConsoleHeader
