import React, { useState } from "react"
import "./index.scss"

function HostOrderStep2() {
  const [selectedItem, setSelectedItem] = useState(1)

  return (
    <div className="host-order-step2  ">
      <div className="px-4">
        <div className=" ">
          <div className="text-center pb-4">
            <h5 className="title">Generate new host</h5>
          </div>

          <div className="px-2 mb-sm-4 mb-md-0">
            <div className=" pt-2 py-sm-5 d-flex justify-content-between row ">
              <button
                className={
                  selectedItem === 1
                    ? "host-check-form-selected host-cardpurchase   d-flex justify-content-center col-sm-3 mt-1 ms-sm-2 me-sm-3"
                    : "host-check-form  host-cardpurchase  d-flex justify-content-center col-sm-3 mt-1 ms-sm-2 me-sm-3"
                }
                onClick={() => {
                  setSelectedItem(1)
                }}
              >
                <div>
                  <div className="cheakBox d-flex align-items-center">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 113 113"
                      fill="#AEAEAE"
                    >
                      <g
                        id="noun_cheque_3948542"
                        transform="translate(-4.363 -1)"
                      >
                        <g id="Group_157" transform="translate(4.363 1)">
                          <path
                            id="Path_295"
                            d="M56.5,82.9h-49V41.4h37.7c2.1,0,3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8H3.8c-2.1,0-3.8,1.7-3.8,3.8
              c0,0,0,0,0,0v49c0,2.1,1.7,3.8,3.8,3.8c0,0,0,0,0,0h52.7c2.1,0,3.8-1.7,3.8-3.8C60.3,84.6,58.6,82.9,56.5,82.9L56.5,82.9z"
                          />
                          <path
                            id="Path_296"
                            d="M90.4,15.1v-3.8C90.4,5.1,85.3,0,79.1,0c-6.2,0-11.3,5.1-11.3,11.3v3.8c-2.1,0-3.8,1.7-3.8,3.8c0,0,0,0,0,0
              c-6.2,0-11.3,5.1-11.3,11.3v22.6c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8V30.1c0-2.1,1.7-3.8,3.8-3.8v60.3c0,0,0,0.1,0,0.1
              c0,0.4,0.1,0.8,0.2,1.2c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0.1,0,0.1l11.3,22.6c0.9,1.9,3.2,2.6,5.1,1.7c0.7-0.4,1.3-1,1.7-1.7
              l11.3-22.6c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2c0,0,0-0.1,0-0.1V18.8C94.2,16.8,92.5,15.1,90.4,15.1
              C90.4,15.1,90.4,15.1,90.4,15.1z M75.3,11.3c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8v3.8h-7.5V11.3z M79.1,100.8l-5.2-10.4h10.4
              L79.1,100.8z M86.6,82.9H71.6V22.6h15.1V82.9z"
                          />
                          <path
                            id="Path_297"
                            d="M18.8,52.7c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8h18.8c2.1,0,3.8-1.7,3.8-3.8
              c0-2.1-1.7-3.8-3.8-3.8H18.8z"
                          />
                          <path
                            id="Path_298"
                            d="M56.5,67.8H18.8c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h37.7c2.1,0,3.8-1.7,3.8-3.8S58.6,67.8,56.5,67.8z"
                          />
                          <path
                            id="Path_299"
                            d="M109.2,33.9h-7.5c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8l0,0h3.8v41.4h-3.8c-2.1,0-3.8,1.7-3.8,3.8
              c0,2.1,1.7,3.8,3.8,3.8h7.5c2.1,0,3.8-1.7,3.8-3.8c0,0,0,0,0,0v-49C113,35.6,111.3,33.9,109.2,33.9
              C109.2,33.9,109.2,33.9,109.2,33.9z"
                          />
                        </g>
                      </g>
                    </svg>
                    <h6 className="text-center">Cheque</h6>
                  </div>
                </div>
              </button>
              <button
                className={
                  selectedItem === 2
                    ? "host-check-form-selected host-cardpurchase  d-flex justify-content-center  col-sm-3 mt-1 mx-sm-3"
                    : "host-check-form  host-cardpurchase  d-flex justify-content-center  col-sm-3 mt-1 mx-sm-3"
                }
                onClick={() => {
                  setSelectedItem(2)
                }}
              >
                <div>
                  <div className="cheakBox d-flex align-items-center">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 122.7 88.6"
                      fill="#AEAEAE"
                    >
                      <g id="Group_156" transform="translate(45 -9918)">
                        <g
                          id="noun_bank_card_943995"
                          transform="translate(-45 9918)"
                        >
                          <path
                            id="Path_289"
                            d="M112.5,13.6H92v-3.4C92,4.6,87.4,0,81.8,0H10.2C4.6,0,0,4.6,0,10.2v54.5C0,70.4,4.6,75,10.2,75h20.4v3.4
              c0,5.6,4.6,10.2,10.2,10.2h71.6c5.6,0,10.2-4.6,10.2-10.2V23.9C122.7,18.2,118.1,13.6,112.5,13.6z M10.2,68.2
              c-1.9,0-3.4-1.5-3.4-3.4V10.2c0-1.9,1.5-3.4,3.4-3.4h71.6c1.9,0,3.4,1.5,3.4,3.4v54.5c0,1.9-1.5,3.4-3.4,3.4L10.2,68.2z
               M115.9,78.4c0,1.9-1.5,3.4-3.4,3.4H40.9c-1.9,0-3.4-1.5-3.4-3.4V75h44.3C87.4,75,92,70.4,92,64.8V40.9h23.9V78.4z M115.9,27.3H92
              v-6.8h20.4c1.9,0,3.4,1.5,3.4,3.4L115.9,27.3z"
                          />
                          <path
                            id="Path_290"
                            d="M23.9,47.7H17c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h6.8c1.9,0,3.4-1.5,3.4-3.4S25.7,47.7,23.9,47.7z"
                          />
                          <path
                            id="Path_291"
                            d="M40.9,47.7h-6.8c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h6.8c1.9,0,3.4-1.5,3.4-3.4S42.8,47.7,40.9,47.7z"
                          />
                          <path
                            id="Path_292"
                            d="M57.9,47.7h-6.8c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4l0,0h6.8c1.9,0,3.4-1.5,3.4-3.4
              S59.8,47.7,57.9,47.7z"
                          />
                          <path
                            id="Path_293"
                            d="M75,47.7h-6.8c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4H75c1.9,0,3.4-1.5,3.4-3.4S76.9,47.7,75,47.7z"
                          />
                          <path
                            id="Path_294"
                            d="M17,40.9h17c1.9,0,3.4-1.5,3.4-3.4c0,0,0,0,0,0V23.9c0-1.9-1.5-3.4-3.4-3.4c0,0,0,0,0,0H17
              c-1.9,0-3.4,1.5-3.4,3.4c0,0,0,0,0,0v13.6C13.6,39.4,15.2,40.9,17,40.9L17,40.9z M20.4,27.3h10.2v6.8H20.4L20.4,27.3z"
                          />
                        </g>
                      </g>
                    </svg>{" "}
                    <h6 className="text-center">Carte</h6>
                  </div>
                </div>
              </button>
              <button
                className={
                  selectedItem === 3
                    ? "host-check-form-selected host-cardpurchase d-flex justify-content-center col-sm-3 mt-1 mx-sm-3"
                    : "host-check-form  host-cardpurchase d-flex justify-content-center col-sm-3 mt-1 mx-sm-3 "
                }
                onClick={() => {
                  setSelectedItem(3)
                }}
              >
                <div>
                  <div className="cheakBox d-flex align-items-center">
                    <i className="bi bi-credit-card-fill"></i>
                    <h6 className="text-center">Wire</h6>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HostOrderStep2
