import React, { useContext } from "react"

import TableOfUsers from "./users-list"
import { ProfileContext } from "../../../dashboard/membership-layout"

import "./index.scss"
const UsersTable = () => {
  const currentUser = useContext(ProfileContext)
  return currentUser ? (
    <div id="UsersTable" className="UsersTable" data-testid="UsersTable">
      <div>
        <TableOfUsers data={currentUser.relatedUsers} />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default UsersTable
