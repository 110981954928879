import axios from "axios"
import React, { useContext } from "react"
import { toast } from "react-toastify"

import services from "../../../../../services"
import { ProfileContext } from "../../../dashboard/membership-layout"

import "./index.scss"
const ReadOnlyRow = ({ item, setOffset }) => {
  const currentUser = useContext(ProfileContext)

  const host_id = item.domainIP + (item.port ? ":" + item.port : "")
  const redirect = (item.ssl ? "https" : "http") + "://" + host_id

  const handleDeleteHost = async hostId => {
    const currentHosts = currentUser.hosts || []
    const index = currentHosts.findIndex(host => host.id === hostId)
    const hostToDelete = currentHosts.splice(index, 1)[0]

    let cleanAWServerResult = { success: true }
    if (hostToDelete?.awsConfig) {
      cleanAWServerResult = await cleanAWServer([
        hostToDelete.awsConfig.instanceId,
      ])
    }

    if (cleanAWServerResult.success) {
      await services.db.deleteClientHost(host_id)
      await services.db.updateUserHosts(currentUser, currentHosts)

      if (currentHosts.length % 3 === 0) {
        setOffset(0)
      }
    } else {
      toast.error("Error deleting the aws host")
      console.error("error deleting the aws host", cleanAWServerResult)
    }
  }

  const RenderStatusComponent = () => {
    switch (item.awsConfig?.status) {
      case "running":
        return <strong className="text-success">Active</strong>
      case "error":
        return <strong className="text-error">Error</strong>
      case "pending":
        return <strong className="text-warning">Pending</strong>
      default:
        return <></>
    }
  }

  return (
    <>
      <tr className="table-row">
        <td data-label="Name">{item.name}</td>
        <td data-label="Code">{item.code}</td>
        <td data-label="Domain/IP">
          <a href={redirect} target="_blank" rel="noopener noreferrer">
            {item.domainIP}
          </a>
        </td>
        <td data-label="Port">{item.port}</td>
        <td data-label="Status">
          {item.awsConfig && (
            <code className=" status-btn">{RenderStatusComponent()}</code>
          )}
        </td>

        <td className="editbutton">
          <i
            className="bi bi-trash px-2"
            data-bs-toggle="modal"
            data-bs-target={`#deleteModal_${item.id}`}
          ></i>
          <div
            className="modal fade"
            id={`deleteModal_${item.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="deleteModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body text-center">
                  Are you sure you wanna delete this Host
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="cancel-host-btn"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="add-host-btn"
                    data-bs-dismiss="modal"
                    onClick={() => handleDeleteHost(item.id)}
                  >
                    Delete Host
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

const cleanAWServer = async (instanceIds = [""]) => {
  try {
    const response = await axios.post(
      `https://us-central1-imaxproject-c13a5.cloudfunctions.net/deleteAWServer`,
      { instanceIds },
      {
        headers: {
          authorization: `Bearer ${services.auth.auth.currentUser.stsTokenManager.accessToken}`,
        },
      }
    )

    return response.data
  } catch (error) {
    console.error("error while trying to delete aws server instances", error)
    return {
      success: false,
      content: null,
      error,
      errorText: "error while trying to delete aws server instances",
    }
  }
}

export default ReadOnlyRow
