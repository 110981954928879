import { Link } from "gatsby"
import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const Documentation = ({
  info = [
    {
      Title: "A Quick Start with MAXapps",
      Lien: "/docs/get-started/quickinstall/",
    },
    {
      Title: "Add a Widget to Your MAXapps",
      Lien: "/docs/how-to/build/widget/",
    },
    {
      Title: "Build Application Using MAXapps Designer",
      Lien: "/docs/how-to/build/designer/",
    },
  ],
}) => {
  return (
    <div className="card-documentations" id="card-documentations">
      <h5>Documentation</h5>
      <div className="row justify-content-between cards-container">
        {info?.map((item, index) => {
          return (
            <Link
              className=" col-12 col-sm-6  col-md-4 "
              key={index}
              to={item.Lien}
            >
              <div className="card pt-4 pb-4">
                {item.Title}
                <i className="bi bi-file-earmark-text mt-3"></i>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Documentation
