import React from "react"

import CardPlan from "./cardPlan"

const SelectPlan = ({ pricingCards, startPurchase = () => {} }) => {
  return (
    <div className="row  justify-content-center py-3 ">
      {pricingCards?.map((item, index) => {
        return (
          <button
            className="col-xl-6 cardplan-btn"
            onClick={() => {
              startPurchase instanceof Function && startPurchase(item.code)
            }}
            key={index}
          >
            <CardPlan {...item} />
          </button>
        )
      })}
    </div>
  )
}

export default SelectPlan
