import React from "react"
import Seo from "../../seo"

import ZendeskButton from "../../zendesk"

import "./index.scss"
const LoginLayout = ({ children }) => {
  return (
    <div id="LayoutLightt" className=" LayoutLightt">
      <Seo />
      <div className="container ">
        <div className="trans">
          {children}
          <div className="back-submit">
            <div type="submit" className="form-button back-button ">
              <a href="/">
                <i className="bi bi-arrow-left-short "></i>
                Back
              </a>
            </div>
          </div>
        </div>
      </div>

      <ZendeskButton />
    </div>
  )
}

export default LoginLayout
