import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import OrderStep2 from "./order-step2"
import Pay2 from "../../../payment/pay2"
import CardPlan from "../../plan-order/plan/cardPlan"
import { getFromStorage } from "../../../../../services/utils"

import "./index.scss"
import FormCheque from "./order-step3/FormCheque"
const Order = ({ description = "" }) => {
  let currentPurchase,
    count = 1,
    unitPrice = 49,
    type = "DIY"

  if (
    typeof window !== "undefined" &&
    localStorage.getItem("currentPurchase")
  ) {
    try {
      currentPurchase = JSON.parse(localStorage.getItem("currentPurchase"))

      type = currentPurchase?.type ? currentPurchase.type : type
      count = currentPurchase ? Number(currentPurchase.count) : count
      unitPrice =
        currentPurchase && currentPurchase.price
          ? Number(currentPurchase.price.replace("$", "").trim())
          : unitPrice
    } catch (error) {
      console.error("error getting the current Purchase ", error)
    }
  }
  let pricingCards = []

  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(pricing-cards)/" }) {
          exports {
            pricingCard {
              btn
              list {
                imgSrc
                specification
              }
              price {
                price
                trial
              }
              subTitle
              title
              code
            }
          }
          frontmatter {
            subTitleMainPricing
            titlecolored
            blackTitle
            bottominformations
            bottomButtonTitle
          }
        }
      }
    `)

    pricingCards = result.mdx.exports.pricingCard
  } catch (error) {
    pricingCards = []
  }
  const diyPricingCard = pricingCards?.find(
    data => data?.code.toUpperCase() === "DIY"
  )
  const [quantity, setQuantity] = useState(count)
  const [currentStep, setCurrentStep] = useState(1)
  const [data, setData] = useState()
  const [selectedItem, setSelectedItem] = useState(1)

  const handleNext = () => {
    setCurrentStep(currentStep + 1)
    if (currentStep === 2) handleCheckout()
  }
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleCheckout = () => {
    typeof window !== "undefined" && localStorage.removeItem("currentPurchase")
    payDirectly()
  }

  const payDirectly = async () => {
    const purchaseLs = {
      count: quantity,
      type,
      price: unitPrice,
    }
    setData(purchaseLs)

    // pay(purchaseLs)
    setCurrentStep(currentStep + 1)
  }
  return (
    <div className="row licence-order">
      <div id="Order" className="col-lg-7 col-12   order">
        {currentStep === 1 && (
          <div className="first-step">
            <div className=" p-md-3  ">
              <div className=" py-1">
                <p className=" ps-3 pt-4 order-val">{type.toUpperCase()}</p>
              </div>

              <div className="row pb-4 px-4 details-text">
                <div className="col-12 form-group">
                  <label className="order-lbl">Number of users</label>
                  {currentPurchase ? (
                    quantity
                  ) : (
                    <input
                      type="number"
                      className="form-control edit-input"
                      value={quantity}
                      onChange={e => {
                        e.target.value
                          ? setQuantity(parseInt(e.target.value))
                          : setQuantity(1)
                      }}
                      id="Quantity"
                      placeholder="Quantity"
                    />
                  )}
                </div>
                <div className="total-price col-12 pt-3">
                  <p className="total-desc">Total </p>
                  {unitPrice > 0 && (
                    <>
                      <p className="total">{unitPrice * quantity} </p>
                      <p className="total">$</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <OrderStep2
            type={type}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
        {currentStep === 3 &&
          (selectedItem === 1 ? (
            <div className="row final-step">
              <FormCheque type={type} />
            </div>
          ) : (
            <div className="row final-step">
              <Pay2 data={data} />
            </div>
          ))}
      </div>
      {currentStep === 1 ? (
        <div className="col-lg-5 col-12 selected-card ">
          <CardPlan {...diyPricingCard} type={"complete"} />
        </div>
      ) : (
        <div className="col-lg-5 col-12 summary-desc ">
          <p className="summary text-start pt-3 m-1">Summary Plan</p>
          <div className="row pt-4">
            <div className="col-3">
              <p className="desc ">Quantity</p>
            </div>
            <div className="col-4">
              <p className="desc ">{quantity} Account</p>
            </div>
            <div className="col-5">
              <div className="trial px-2 py-1 ">2 Free Accounts</div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p className="total-desc ">Total </p>
            </div>
            <div className="col-9">
              <p className="total">{unitPrice * quantity} $</p>
            </div>
          </div>
        </div>
      )}
      {currentStep !== 3 && (
        <div className="row py-4">
          <div className="col-lg-6 col-6  prvbtn ">
            {currentStep !== 1 && (
              <button className="mx-3 mt-3 m-3  " onClick={handlePrevious}>
                <i className="ms-2 bi bi-arrow-left"></i>
                Previous
              </button>
            )}
          </div>
          <div className="col-lg-6 col-6  nextbtn ">
            <button
              className="mx-3 mt-3 m-3  "
              disabled={true}
              onClick={() => {}}
              // onClick={handleNext}
            >
              Next
              <i className="ms-2 bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
export default Order
