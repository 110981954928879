import React, { useState } from "react"
import * as Yup from "yup"
import { navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth"

import Services from "../../../../../services"

import "./index.scss"
import LottieAnimation from "../../../../../animations/lottie"
const ResetPwdForm = () => {
  const params = new URLSearchParams(window.location.href)
  const oobCode = params.get("oobCode")

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, "Password should be at least 6 characters long")
      .required("*Please enter the new password"),
    confirmPassword: Yup.string().when("newPassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both passwords need to be the same"
      ),
    }),
  })

  const [responseMsg, setResponseMsg] = useState({
    show: false,
    msg: null,
  })

  return (
    <div id="ResetPwdForm" className="ResetPwdForm ">
      <Formik
        initialValues={{ newPassword: "", confirmPassword: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setResponseMsg({ show: false, msg: null })
          setSubmitting(true)

          verifyPasswordResetCode(Services.auth.auth, oobCode)
            .then(async email => {
              // Save the new password.
              confirmPasswordReset(
                Services.auth.auth,
                oobCode,
                values.newPassword
              )
                .then(async resp => {
                  await Services.auth.signIn(email, values.newPassword)
                  navigate("/console/")
                })
                .catch(error => {
                  console.error(error)
                  setResponseMsg({
                    show: true,
                    msg: "Error occurred during confirmation. The code might have expired or the password is too weak",
                  })
                  setSubmitting(false)
                })
            })
            .catch(error => {
              console.error(error)
              setResponseMsg({
                show: true,
                msg: "Invalid or expired action code. try to reset the password again.",
              })
              setSubmitting(false)
            })
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <div className="form-input">
              <label htmlFor="New-Password" className="form-label">
                New Password
              </label>
              <Field
                type="password"
                name="newPassword"
                className={`form-control ${
                  touched.newPassword && errors.newPassword ? "error" : null
                }`}
              />
              <ErrorMessage
                name="newPassword"
                component="div"
                className="error-message"
              />
            </div>
            <div className="form-input">
              <label htmlFor="Confirm-Password" className="form-label">
                Confirm Password
              </label>
              <Field
                type="password"
                name="confirmPassword"
                className={`form-control ${
                  touched.confirmPassword && errors.confirmPassword
                    ? "error"
                    : null
                }`}
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error-message"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="form-button next-button my-4 px-4"
            >
              Next
              {!isSubmitting && <i className="bi bi-arrow-right-short "></i>}
              {isSubmitting && (
                <div className="login-loader">
                  <LottieAnimation height={25} width={25} />
                </div>
              )}
            </button>
            {responseMsg.show && (
              <div className="row error-msg">
                <i className="bi bi-exclamation-triangle-fill col-3 col-sm-2"></i>
                <h6 className="col-9 col-sm-10">{responseMsg.msg}</h6>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ResetPwdForm
