import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"

import UsersFilter from "./users-filter"

import "./index.scss"
function TableOfUsers({ data = [] }) {
  const [filtervalue, setFiltervalue] = useState("")

  const perPage = 5
  const pageCount = Math.ceil(data.length / perPage)

  const [offset, setOffset] = useState(0)
  const [pagidata, setPagidata] = useState(data.slice(offset, offset + perPage))

  useEffect(() => {
    data = data.filter(
      user =>
        user.userName.toLowerCase().includes(filtervalue) ||
        user.host.toLowerCase().includes(filtervalue) ||
        convertdate(user.connexionDate).includes(filtervalue) ||
        convertTime(user.connexionDate).includes(filtervalue)
    )
    if (data.length % 5 === 0) {
      setOffset(0)
      setPagidata(data ? Object.values(data).slice(0, perPage) : [])
    } else
      setPagidata(
        data ? Object.values(data).slice(offset, offset + perPage) : []
      )
  }, [data.length, filtervalue])

  const handleLogoutUser = () => {
    console.log("Handle user logout")
  }

  const handlePageClick = e => {
    const offset = e.selected * perPage
    setOffset(offset)
    setPagidata(data.slice(offset, offset + perPage))
  }
  const convertdate = date => {
    return new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date)
  }

  const convertTime = date => {
    return new Intl.DateTimeFormat("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(date)
  }

  return (
    <div id="TableOfUsers" className="TableOfUsers" data-testid="TableOfUsers">
      <UsersFilter setFiltervalue={setFiltervalue} title={"Users List"} />

      <table>
        <thead>
          <tr>
            <th> Host </th>
            <th> Name </th>
            <th> Connexion date </th>
            <th> Connexion time </th>
          </tr>
        </thead>

        <tbody>
          {pagidata.map((item, index) => {
            return (
              <tr key={index}>
                <td data-label="Host">{item.host}</td>
                <td data-label="Name">{item.userName}</td>
                <td data-label="Connexion date">
                  {convertdate(item.connexionDate)}
                </td>
                <td data-label="Connexion time">
                  {convertTime(item.connexionDate)}
                </td>

                <td data-label="Action">
                  <button
                    className="delete-btn"
                    onClick={() => handleLogoutUser(item.id)}
                  >
                    <i className="bi bi-box-arrow-right"></i>
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      {data.length ? (
        <div className="table-pagination">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={".."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <div className="no-items text-center py-2">
          <h6>No items to display</h6>
        </div>
      )}
    </div>
  )
}

export default TableOfUsers
