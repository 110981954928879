import * as Yup from "yup"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"

import Services from "../../../../../services"
import LottieAnimation from "../../../../../animations/lottie"
import { hasFreeDomain } from "../../../../../services/utils"

import "./index.scss"
const RegisterForm = () => {
  const [signInError, setSignInError] = useState({ show: false, msg: null })

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(50, "*Name must be less than 50 characters")
      .min(3, "*Name must be at least 3 characters long")
      .required("*Name is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    newPassword: Yup.string()
      .min(6, "Password should be at least 6 characters long")
      .required("*Please enter the new password"),
    confirmPassword: Yup.string().when("newPassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both passwords need to be the same"
      ),
    }),
  })

  return (
    <div id="RegisterForm" className="register-form">
      <Formik
        initialValues={{
          name: "",
          email: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        validate={values => {
          let errors = values.email ? {} : { email: "*This field is required" }

          errors =
            values.email && hasFreeDomain(values.email)
              ? { ...errors, email: "You can't use a free email address!" }
              : errors

          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSignInError({ show: false, msg: null })
          setSubmitting(true)

          let plan = ""
          const params = new URLSearchParams(window.location.search)
          plan = params?.get("title")

          const res = await Services.auth.signUp(
            values.name,
            values.email.toLowerCase().trim(),
            plan,
            values.newPassword
          )
          if (res.success) {
            localStorage.clear()
            navigate("/console/welcome-msg/")
          } else setSignInError({ show: true, msg: res.message })

          setSubmitting(false)
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <div className="form-input">
              <label htmlFor="Name" className="form-label">
                Name
              </label>
              <Field
                name="name"
                className={`form-control ${
                  touched.name && errors.name ? "error" : null
                }`}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="error-message"
              />
            </div>
            <div className="form-input">
              <label htmlFor="Email" className="form-label">
                Email Address
              </label>
              <Field
                type="email"
                name="email"
                className={`form-control ${
                  touched.email && errors.email ? "error" : null
                }`}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="error-message"
              />
            </div>
            <div className="form-input">
              <label htmlFor="Password" className="form-label">
                Password
              </label>
              <Field
                type="password"
                name="newPassword"
                className={`form-control ${
                  touched.newPassword && errors.newPassword ? "error" : null
                }`}
              />
              <ErrorMessage
                name="newPassword"
                component="div"
                className="error-message"
              />
            </div>
            <div className="form-input">
              <label htmlFor="Confirm-Password" className="form-label">
                Confirm Password
              </label>
              <Field
                type="password"
                name="confirmPassword"
                className={`form-control ${
                  touched.confirmPassword && errors.confirmPassword
                    ? "error"
                    : null
                }`}
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error-message"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="form-button register-button"
            >
              Sign up
              {isSubmitting && (
                <div className="login-loader">
                  <LottieAnimation height={25} width={25} />
                </div>
              )}
            </button>
            {signInError.show && (
              <div className="row sign-in-error">
                <i className="bi bi-exclamation-triangle-fill col-3 col-sm-2"></i>
                <h6 className="col-9 col-sm-10">Error: {signInError.msg}</h6>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default RegisterForm
