import React from "react"
import "./index.scss"
const UsersFilter = ({ setFiltervalue, title = "" }) => {
  return (
    <div id="UsersFilter" className="UsersFilter" data-testid="UsersFilter">
      <div className="table-heading">
        <span className="table-title" data-testid="users-list">
          <img alt="" src="/users.svg" />
          {title}
        </span>
        <form className="table-search d-flex flew-row">
          <input
            onChange={e => {
              setFiltervalue(e.target.value.toLocaleLowerCase())
            }}
            className="search-icon"
            placeholder="Search ..."
            data-testid="search-input"
          />
        </form>
      </div>
    </div>
  )
}

export default UsersFilter
