import React, { useEffect, useRef, useState } from "react"

import { sendNewsLetterMail } from "../../../services/mail"
import ContactTable from "./contact-list"
import service from "../../../services"

function ContactList() {
  const [contacts, setContacts] = useState([])
  const [modalContacts, setModalContacts] = useState([])
  const selectedContacts = useRef([])

  const handleSendMail = async usersList => {
    const mailList = usersList.map(user => user.email)
    await sendNewsLetterMail(mailList)
  }
  useEffect(() => {
    service.db
      .getAllContacts()
      .then(res => {
        setContacts(res)
      })
      .catch(err => {
        console.error("error while retreving the contacts list: ", err)
      })
  }, [])

  return (
    <div id="ContactList" className="contact-list row">
      <div className="col-md-12">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target={`#sendNewsLetterModal`}
          className="btn btn-success"
          onClick={() => {
            setModalContacts(selectedContacts.current)
          }}
        >
          Send newsletter <i className="bi bi-envelope"></i>
        </button>
        <div
          className="modal fade"
          id={`sendNewsLetterModal`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="sendNewsLetterLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                {modalContacts.length > 0 ? (
                  <>
                    Send newsletter mail to these contacts?
                    <br />
                    <br />
                    {modalContacts.map(contact => (
                      <>
                        {contact.email}
                        <br />
                      </>
                    ))}
                    <br />
                  </>
                ) : (
                  <>please select contacts from the list below.</>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="cancel-host-btn"
                  data-bs-dismiss="modal"
                >
                  cancel
                </button>
                <button
                  type="button"
                  className="add-host-btn"
                  data-bs-dismiss="modal"
                  disabled={!modalContacts.length}
                  onClick={async () => {
                    console.log("sending newsletter")
                    handleSendMail(modalContacts)
                  }}
                >
                  send Newsletter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <ContactTable
          title="Contact List"
          columns={columns}
          data={contacts}
          selectedContacts={selectedContacts}
        />
      </div>
    </div>
  )
}

const columns = [
  { title: "Email", field: "email", editable: "onAdd" },
  {
    title: "Contact Type",
    field: "contactType",
    lookup: {
      partner: "Partner",
      compaign: "Compaign",
      client: "Client",
      propspect: "Propspect",
    },
  },
  {
    title: "subscribed?",
    field: "subscribedToNewsletter",
    lookup: { true: "yes", false: "no" },
  },
]

export default ContactList
