import React from "react"
import LottieAnimation from "../../animations/lottie"

import "./index.scss"
export default function Redirection({ location }) {
  const params = new URLSearchParams(location.search)
  const continueUrl = params.get("continueUrl")

  typeof window !== "undefined" &&
    params.get("successfulStripeCheckout") &&
    localStorage.setItem("checkout", params.get("successfulStripeCheckout"))

  setTimeout(() => {
    window.location.href = `${continueUrl}${location.search.replace(
      `continueUrl=${continueUrl}`,
      ""
    )}`
  }, 1000)

  return (
    <div id="Redirection" className="Redirection" data-testid="Redirection">
      <div className="container">
        <div className="redirection-loader">
          <LottieAnimation height={300} width={300} />
        </div>
      </div>
    </div>
  )
}
