import React from "react"
import CardPlan from "../plan/cardPlan"
import ContactForm from "./form"
import "./index.scss"

const entreprisePricingCard = {
  title: "",
  code: "",
  price: null,
  btn: "",
  subTitle:
    "For business looking for managed service and good price on bulk purchase",
  list: [
    {
      imgSrc: "/license.svg",
      specification:
        "Concurrent type: you can have mutiple shift using the same license.",
    },
    {
      imgSrc: "/pricing.svg",
      specification: "Better pricing per user of more than 30 users",
    },
    {
      imgSrc: "/installation.svg",
      specification: "End to end service, from installation to support",
    },
    {
      imgSrc: "/unlimitedEdit.svg",
      specification: "Unlimited change requests",
    },
    { imgSrc: "/premium.svg", specification: "Premium support" },
  ],
}
const EntreprisePlan = () => {
  return (
    <div
      id="entreprise-plan"
      className="row entreprise-plan"
      data-testid="entreprise-plan"
    >
      <div className="col-12 col-lg-6">
        <h2 className="title">Enterprise</h2>
        <ContactForm />
      </div>
      <div className="d-none d-lg-block col-12 col-lg-6 form    ">
        <CardPlan {...entreprisePricingCard} />
      </div>
    </div>
  )
}

export default EntreprisePlan
