import React from "react"

import Seo from "../../seo"

import "./index.scss"
const LayoutGray = ({ children }) => {
  return (
    <div id="LayoutGray" className=" LayoutGray">
      <Seo
        title="Console"
        description="Please get in touch if you have any question left about Products, Sales, or Technical Support"
      />
      <div className="">{children}</div>
    </div>
  )
}

export default LayoutGray
