import React, { useEffect, useState } from "react"
import LottieAnimation from "../../../../animations/lottie"
import service from "../../../../services"
import firebaseServices from "../../../../services/firebase/init"

import "./index.scss"
const sendEmailsVerificationLink = firebaseServices.getCloudFunction(
  "sendEmailsVerificationLink"
)
const ListUsers = () => {
  const [users, setUsers] = useState([])
  const [showLoader, setShowLoader] = useState(true)

  const fetchUsers = async () => {
    const res = await service.db.getAllUser()
    setUsers(res)
  }
  const ActivateUser = async id => {
    const res = await sendEmailsVerificationLink({
      useremail: id,
    })
    await service.db.editUSer(id, {
      emailApproved: true,
    })
  }

  useEffect(() => {
    const intervalId = setInterval(async () => {
      fetchUsers()
      setShowLoader(false)
    }, 2000)
    return () => {
      clearInterval(intervalId)
    }
  }, [users])
  return (
    <div id="ListUsers" className="ListUsers">
      <div className="container">
        {showLoader ? (
          <LottieAnimation height={100} width={100} />
        ) : (
          <div className="row">
            <div className="col-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">User Email</th>
                    <th scope="col">Creation Date</th>

                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((data, index) => {
                    return (
                      !data.emailApproved && (
                        <tr key={index}>
                          <td>{data.profile.email}</td>
                          <td>
                            {data.profile.createdAt &&
                              new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(data.profile.createdAt)}
                          </td>

                          <td>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target={`#deleteModal_${index}`}
                              className="btn btn-success"
                            >
                              <i className="bi bi-check-square"></i>
                            </button>
                            <div
                              className="modal fade"
                              id={`deleteModal_${index}`}
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="deleteModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-body text-center">
                                    Approve {data.profile.email} ?
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="cancel-host-btn"
                                      data-bs-dismiss="modal"
                                    >
                                      No
                                    </button>
                                    <button
                                      type="button"
                                      className="add-host-btn"
                                      data-bs-dismiss="modal"
                                      onClick={() =>
                                        ActivateUser(data.profile.email)
                                      }
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default ListUsers
