import React, { useState } from "react"
import "./index.scss"

function HostOrderStep1({ setselectedCard, generateFreeHost = () => {} }) {
  const [selectedItem, setSelectedItem] = useState(1)
  return (
    <div id="hostOrderStep1" className="hostOrderStep1">
      <div className="text-center pb-4">
        <h5 className="title">Generate new host</h5>
      </div>
      <div className="mx-4 mt-4">
        <div className="type row justify-content-between ">
          <div
            className={
              selectedItem === 1
                ? "col-xl-5 card-host-2  mt-2"
                : "col-xl-5 card-host-1  mt-2"
            }
            onClick={() => {
              setSelectedItem(1)
              setselectedCard(1)
              // generateFreeHost()
            }}
          >
            <i className="bi bi-gift "></i>
            <div className="ps-3 pt-2">
              <h6 className="card-title">Free Session</h6>
              <h6 className="card-title2">24H</h6>
            </div>
          </div>
          <div
            className={
              selectedItem === 2
                ? "col-xl-5 card-host-2 mt-2 "
                : "col-xl-5 card-host-1 mt-2 "
            }
            // onClick={() => {
            //   setSelectedItem(2)
            //   setselectedCard(2)
            // }}
          >
            <i className="bi bi-cart-dash "></i>
            <div className="ps-3 pt-2">
              <h6 className="card-title">Purchase Licence</h6>
              <h6 className="card-title2">1 year (365 days)</h6>
            </div>
          </div>
        </div>
        <div className="description">
          <p className="mt-4 ">
            Lorem lorem Lorem loremLorem lorem Lorem lorem Lorem loremLorem
            loremLorem loremLorem lorem
          </p>
        </div>
      </div>
    </div>
  )
}

export default HostOrderStep1
