import React from "react"

import "./index.scss"
const CardPlan = props => {
  const { title, subTitle, price, list, btn, type = "" } = props

  return (
    <div
      id="CardPlan"
      className={
        type !== "complete"
          ? "CardPlan cardPlanBorder py-3 "
          : "CardPlan  py-3 "
      }
      data-testid="CardPlan"
    >
      {type !== "complete" ? (
        <>
          {price && (
            <div className="title row">
              <div className="col-6 row">
                <h4 className="titleStyle py-1 col-2 ">{title}</h4>
              </div>
              <div className="col-6 d-flex">
                <div className="trialtop px-2 py-1 ">
                  <i className="bi bi-gift"></i> 2 Free Accounts
                </div>
                <p className="price ">
                  <span className="prix">{price.price}/mo</span>
                </p>
              </div>
            </div>
          )}
          {btn && (
            <div className="title row">
              <div className="col-7">
                <h4 className="titleStyle text-start py-1">{title}</h4>
              </div>
              <div className="col-5 col-xl-5 text-lg-center  btnContainer py-lg-2 py-xl-0 pt-1">
                <p className="price ">
                  <span className="prix">Contact us</span>
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="summary text-start   m-1">Summary Plan</p>
      )}

      <div className=" lisContainer py-3 ">
        <div className="myList d-flex  row ">
          <div className="col-11">
            <li className="d-flex   p-0 ">
              <p className="ligne text-start p-0 m-1">{subTitle}</p>
            </li>
            {list.map((item, index) => {
              return (
                <li key={index} className="d-flex   p-0 ">
                  <img
                    alt=""
                    src={item.imgSrc}
                    className="img-icon  img-fluid mx-1 "
                  />

                  <p className="ligne text-start p-0 m-1">
                    {item.specification}
                  </p>
                </li>
              )
            })}
            {type === "complete" && (
              <div className="trial px-2 py-1 ">
                <i className="bi bi-gift"></i> 2 Free Accounts
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardPlan
